// https://fontawesome.com/v4.7.0/icons/
const icons = {
  accurate: 'fa fa-bullseye',
  activate: 'fa fa-plus-square',
  add: 'fa fa-plus-circle',
  admin: 'fa fa-lock',
  agree: 'fa fa-check',
  alert: {
    map: 'fa fa-map-marker fa-lg',
    fee: 'fa fa-usd fa-lg'
  },
  asterix: 'fa fa-asterisk',
  at: 'fa fa-at',
  back: 'fa fa-arrow-left',
  beer: 'fa fa-beer',
  blog: 'fa fa-square',
  book: 'fa fa-book',
  calculator: 'fa fa-calculator',
  calendar: 'fa fa-calendar',
  car: 'fa fa-car',
  cart: 'fa fa-2x fa-shopping-cart',
  cart1: 'fa fa-shopping-cart',
  cent: 'fa fa-crosshairs',
  checkout: 'fa fa-credit-card fa-lg',
  chevron: {
    left: 'fa fa-chevron-left',
    right: 'fa fa-chevron-right',
    up: 'fa fa-chevron-up',
    down: 'fa fa-chevron-down'
  },
  circle: {
    solid: 'fa fa-circle',
    dot: 'fa fa-dot-circle'
  },
  clip: 'fa fa-clipboard',
  clock: 'fa fa-clock-o',
  close: 'fa fa-times',
  copy: 'fa fa-clone',
  creditcard: 'fa fa-credit-card',
  data: 'fa fa-database',
  deactivate: 'fa fa-minus-square',
  delivery: 'fa fa-truck',
  desktop: 'fa fa-desktop',
  dollar: 'fa fa-dollar',
  dollarbill: 'fa fa-money',
  download: 'fa fa-download',
  down: 'fa fa-caret-down',
  drink: 'fa fa-glass',
  driver: 'fa fa-dollar',
  edit: 'fa fa-pencil',
  email: 'fa fa-envelope',
  emailOutlined: 'fa fa-envelope-o',
  empty: 'fa fa-trash',
  error: 'fa fa-exclamation-triangle',
  faq: 'fa fa-info-circle',
  favs: 'fa fa-star',
  fire: 'fa fa-fire',
  fix: 'fa fa-wrench',
  flag: 'fa fa-flag',
  flask: 'fa fa-flask',
  flatOutline: 'fa fa-flag-o',
  flavor: 'fa fa-tint',
  food: 'fa fa-cutlery',
  github: 'fa fa-github',
  grain: 'fa fa-pagelines',
  grid: {
    sm: 'fa fa-th',
    lg: 'fa fa-th-large'
  },
  hash: 'fa fa-hashtag',
  help: 'fa fa-question-circle',
  hide: 'fa fa-eye-slash',
  home: 'fa fa-home',
  hourglass: 'fa fa-hourglass-o',
  info: 'fa fa-info',
  infocircle: 'fa fa-info-circle',
  insta: 'fa fa-instagram',
  issues: 'fa fa-exclamation-triangle',
  lean: 'fa fa-pied-piper',
  left: 'fa fa-caret-left',
  lgdrink: 'fa fa-glass fa-lg',
  lgdelivery: 'fa fa-truck fa-lg',
  lgtextboxcancel: 'fa fa-lg fa-times',
  lgtextboxcancelselected: 'fa fa-lg fa-times-circle-o',
  link: 'fa fa-link',
  linkedin: 'fa fa-linkedin-square',
  list: 'fa fa-list',
  listth: 'fa fa-th-list',
  load: 'fa fa-arrow-up',
  loading: {
    circle: 'fa fa-circle-o-notch fa-spin',
    cog: 'fa fa-cog fa-spin',
    gear: 'fa fa-gear fa-spin',
    refresh: 'fa fa-refresh fa-spin',
    spinner: 'fa fa-spinner fa-spin'
  },
  location: 'fa fa-location-arrow',
  map: 'fa fa-map',
  marker: 'fa fa-map-marker',
  menuClose: 'fa fa-2x fa-times',
  menuOpen: 'fa fa-2x fa-bars',
  minus: 'fa fa-minus',
  mobile: 'fa fa-mobile',
  name: 'fa fa-user',
  options: 'fa fa-filter',
  order: 'fa fa-shopping-bag',
  orders: 'fa fa-cart-plus',
  percent: 'fa fa-percent',
  phone: 'fa fa-phone',
  phoneSquare: 'fa fa-phone-square',
  picture: 'fa fa-picture-o',
  plus: 'fa fa-plus',
  privacy: 'fa fa-lock',
  question: 'fa fa-question',
  random: 'fa fa-random',
  right: 'fa fa-caret-right',
  root: 'fa fa-circle-o',
  rotate: {
    left: 'fa fa-rotate-left',
    right: 'fa fa-rotate-right'
  },
  refresh: 'fa fa-refresh',
  repeat: 'fa fa-repeat',
  road: 'fa fa-road',
  rules: 'fa fa-list-alt',
  sale: 'fa fa-thumbs-up',
  save: 'fa fa-floppy-o',
  search: 'fa fa-search',
  secure: 'fa fa-shield',
  sent: 'fa fa-paper-plane',
  server: 'fa fa-server',
  session: 'fa fa-id-badge',
  settings: 'fa fa-sliders',
  showAll: 'fa fa-search-minus',
  signin: 'fa fa-sign-in',
  signup: 'fa fa-user-plus',
  signout: 'fa fa-sign-out',
  shuffle: 'fa fa-random',
  socket: 'fa fa-plug',
  sort: {
    asc: 'fa fa-sort-asc',
    desc: 'fa fa-sort-desc',
    alpha: {
      asc: 'fa fa-sort-alpha-asc',
      desc: 'fa fa-sort-alpha-desc'
    },
    num: {
      asc: 'fa fa-sort-numeric-asc',
      desc: 'fa fa-sort-numeric-desc'
    },
    generic: {
      asc: 'fa fa-sort-amount-asc',
      desc: 'fa fa-sort-amount-desc'
    }
  },
  sub: 'fa fa-circle',
  tag: 'fa fa-tag',
  telegram: 'fa fa-paper-plane',
  terminal: 'fa fa-terminal',
  textboxgo: 'fa fa-lg fa-arrow-right',
  textboxcancel: 'fa-times',
  textboxcancelselected: 'fa-times-circle-o',
  tidy: 'fa fa-eraser',
  tip: 'fa fa-smile-o',
  tos: 'fa fa-file',
  twitter: 'fa fa-twitter',
  twitterSquare: 'fa fa-twitter-square',
  up: 'fa fa-caret-up',
  user: 'fa fa-user',
  users: 'fa fa-users',
  view: 'fa fa-eye',
  wage: 'fa fa-line-chart',
  warning: 'fa fa-exclamation-triangle',
  zoom: 'fa fa-search-plus'
}
export default icons
