// keeps track of how many tabs a user may have open

import storage from './storage'

// keep track of open tabs
export default {
  dec: () => {
    const tabCount = storage.getItem(storage.keys.tabs)
    const tabCountInt = parseInt(tabCount)

    if (!Number.isNaN(tabCountInt) && tabCountInt > 0) {
      storage.setItem(storage.keys.tabs, tabCountInt - 1)
    }
    sessionStorage.setItem(storage.keys.tabId, null)
  },
  inc: () => {
    const tabCount = storage.getItem(storage.keys.tabs)
    let tabCountInt = parseInt(tabCount)
    const tabId = sessionStorage.getItem(storage.keys.tabId)
    const tabIdInt = parseInt(tabId)

    if (!Number.isNaN(tabCountInt)) {
      tabCountInt += 1
    } else {
      tabCountInt = 1
    }
    storage.setItem(storage.keys.tabs, tabCountInt)

    if (Number.isNaN(tabIdInt)) {
      sessionStorage.setItem(storage.keys.tabId, tabCountInt)
    }
  }
}