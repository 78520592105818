Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.human = exports.milWithMin = exports.getDateString = exports.mins = exports.hrs = exports.getMinutes = exports.getHours = exports.openRanges = exports.niceDay = exports.getEstHours = exports.getDay = exports.estObj = exports.dayIndex = undefined;

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _config = require('../config');

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var timeZone = 'America/Toronto';
/*
  returns EST as an object:
  {
    years: 2018,
    months: 8,
    date: 3,
    hours: 11,
    minutes: 17,
    seconds: 13,
    milliseconds: 103
  }
*/
var estObj = function estObj() {
  var nowEastern = void 0,
      dateObj = void 0;
  nowEastern = _momentTimezone2.default.tz(timeZone);
  dateObj = nowEastern.toObject();
  return dateObj;
};

var getEstHours = function getEstHours() {
  var military = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

  var nowEastern = void 0,
      offset = void 0,
      utc = void 0,
      hours = void 0;

  nowEastern = _momentTimezone2.default.tz(timeZone);
  offset = nowEastern.utcOffset() / 60;
  utc = new Date().getUTCHours();
  hours = utc + offset;

  if (hours < 0) {
    hours = 24 + hours;
  }
  if (military) {
    hours *= 100;
  }
  return hours;
};

var dayIndex = function dayIndex() {
  return _momentTimezone2.default.tz(timeZone).day();
};

var dayFull = function dayFull() {
  return _config2.default[dayIndex()];
};

var getDay = function getDay() {
  var day = void 0;
  day = _momentTimezone2.default.tz(timeZone).format('ddd');
  return day.toLowerCase();
};

var niceDay = function niceDay() {
  var withYear = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  var format = void 0;
  format = 'ddd MMM DD' + (withYear ? ', YYYY' : '');
  return _momentTimezone2.default.tz(timeZone).format(format);
};

/*
  openTime, closeTime is an int betwen 0 and 2400
  cutOff is one Hour by default

  openNow: store is open allowing cutOff margin
  closedToday: store is not open
  closedForTheDay: store has closed for the day
*/
var openRanges = function openRanges(openTime, closeTime) {
  var cutOff = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;
  var testEst = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

  var closeOk = void 0,
      estNow = void 0,
      openOk = void 0,
      openingSoon = void 0;
  if (openTime === 0 && closeTime === 0) {
    return {
      openNow: false,
      closedToday: true,
      closedForTheDay: undefined
    };
  }
  estNow = testEst ? testEst : getEstHours();
  openOk = openTime <= estNow;
  closeOk = closeTime - cutOff >= estNow;
  openingSoon = closeOk && !openOk;
  return {
    openNow: openOk && closeOk,
    closedToday: false,
    closedForTheDay: !closeOk,
    openingSoon: openingSoon
  };
};

var getHours = function getHours(milTime) {
  return parseInt(milTime / 100, 10);
};

var getMinutes = function getMinutes(milTime) {
  return parseInt(milTime % 100, 10);
};

var hrs = function hrs(dateTime) {
  return dateTime.getHours();
};

var mins = function mins(dateTime) {
  return dateTime.getMinutes();
};

var getDateString = function getDateString(milTime) {
  var h = void 0,
      m = void 0,
      oDate = void 0;
  h = getHours(milTime);
  m = getMinutes(milTime);
  return new Date('Jan 1, 1980 ' + h + ':' + m);
};

/*
  creates full mil time given hour and minute

*/
var milWithMin = function milWithMin(hr, mn) {
  return hr * 100 + mn;
};

// return 10am for 1000 and 9pm for 2100
// NOTE: timeslots are for rounded hours
// TIDO: deal with hour fractions
var human = function human(mil) {
  var short = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  var am = void 0,
      pm = void 0,
      hrs = void 0;

  if (!short) {
    am = 'am';
    pm = 'pm';
  } else {
    am = 'a';
    pm = 'p';
  }

  hrs = Math.round(mil / 100);

  var indicator = hrs >= 12 ? pm : am;

  if (hrs === 24 || hrs === 0) {
    hrs = 12;
    indicator = am;
  } else if (hrs > 12) {
    hrs -= 12;
  } else {
    // nop
  }
  return '' + hrs + indicator;
};

exports.dayIndex = dayIndex;
exports.estObj = estObj;
exports.getDay = getDay;
exports.getEstHours = getEstHours;
exports.niceDay = niceDay;
exports.openRanges = openRanges;
exports.getHours = getHours;
exports.getMinutes = getMinutes;
exports.hrs = hrs;
exports.mins = mins;
exports.getDateString = getDateString;
exports.milWithMin = milWithMin;
exports.human = human;