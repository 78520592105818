import axios from 'axios'
import to from 'await-to-js'
import { Tart } from '../../utility'
import { Express } from '../../config'
import { actions } from '../index'

// replaces lprod
export default async function lProds(dispatch, rid) {

  const url = `${Express.lProds}/${rid}`
  const [err, resp] = await to(axios.get(url))

  if (err) {
    console.log(err)
    Tart.warn('Unable to load lcbo products [lprods]')
    return
  }
  dispatch({
    type: actions.lcbobeta.lProds,
    value: resp.data,
  })
}