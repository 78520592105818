import React from 'react'
import * as Sentry from '@sentry/browser'
import { HashRouter, Route, Switch } from 'react-router-dom'
import {
  combinedStates,
  actions as A,
} from './reducer'
import Main from './components/MAIN'
import {
  MobileUtils,
  getItem,
  setItem,
  storageKeys as keys,
  tabCounter,
} from './utility'
import { Provider } from 'react-redux'
import { compose } from 'redux'
import ReactDOM from 'react-dom'
import { createStore } from 'redux'
import { env } from './config'
import LogRocket from 'logrocket'

if (!env.IS_LOCAL) {
  console.log('Production: LogRocket and Sentry init...')
  LogRocket.init('uptnl1/craftdelivery')
  Sentry.init({dsn: "https://399de0938b654a0dac0203c0a3764fad@sentry.io/1782208"})
}

/* eslint-disable no-underscore-dangle */
/////////
let store
/////////
let mu = new MobileUtils()
if (env.REDUX_DEV_TOOLS) {
  store = createStore(
    combinedStates,
    compose(
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
        latency: 0
      }
    ))
  )
} else {
  store = createStore(combinedStates)
}

// PLEASE: minimize direct use of store
export default store
///////////////////////////////////////

window.onload = () => {
  setItem(keys.lastLogin, Date.now())
  window.addEventListener('resize', mu.updateDimensions)
  mu.updateDimensions()
  const recentTypes = getItem(keys.recentTypes)
  if (recentTypes) {
    store.dispatch({
      type: A.manage.setRecentTypes,
      value: JSON.parse(recentTypes)
    })
  }
  const recentCities = getItem(keys.recentCities)
  if (recentCities) {
    store.dispatch({
      type: A.manage.setRecentCities,
      value: JSON.parse(recentCities)
    })
  }
  // const hash = getItem(keys.hashLoc)
  // if (hash) {
  //   store.dispatch({ type: A.main.hash, hash })
  // }
  // if (hash === '#/lcbo') {
  //   store.dispatch({ type: A.search.lcbo })
  // }
  return null
}

window.onbeforeunload = () => {
  tabCounter.dec()
  setItem(keys.cart, store.getState().simpleCart.simplecart)
  window.removeEventListener('resize', mu.updateDimensions)
  return null
}

ReactDOM.render((
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route path='/' name='Home' component={Main}/>
      </Switch>
    </HashRouter>
  </Provider>
), document.getElementById('root'))
