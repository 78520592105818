import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import { Location } from '../icons'

const Checkout = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    borderColor: blue[400],
    '&:hover': {
      borderColor: blue[900],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: blue[50],
      color: blue['A400'],
    }
  },
  text: {
    color: blue[500],
    fontWeight: 'bold',
    textTransform: 'none',
  }
}))

export default function ChangeLocation(props) {
  const classes = useStyles()

  return (
    <Checkout
      className={`${classes.button} change-loc-btn`}
      variant='outlined'
      {...props}
    >
      <span className={classes.text}>
        <Location L color={blue['A700']} />
        Change Location
      </span>
    </Checkout>
  )
}