import axios from 'axios'
import to from 'await-to-js'
import { Lambda } from '../../config'
import * as A from '../actions'

// Lambda.initClient will take care of active regions
export default async(dispatch, callback) => {
  const [err, resp] = await to(
    axios.get(Lambda.regions)
  )
  if (err) {
    console.log(err)
    return
  }
  const { regions } = resp.data
  dispatch({
    type: A.regions.list,
    value: regions,
  })
  if (callback) {
    callback(regions)
  }
}
