import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { ChevronLeft } from '../icons'

const S = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    borderColor: green[400],
    '&:hover': {
      borderColor: green[1700],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  text: {
    color: green[500],
    fontWeight: 'bold'
  }
}))

export default function GotoShops(props) {
  const classes = useStyles()
  return (
    <Link to='/bottleshops'>
      <S {...props} variant='outlined'>
        <span className={classes.text}>
          <ChevronLeft icon='left' right={3} L />
          Shops
        </span>
      </S>
    </Link>
  )
}