export default {
  delivery: {
    available: undefined,
    daysClosed: undefined,
    daysOpen: undefined,
    monthly: undefined,
    noContent: false,
    pricing: {},
    schedule: {
      1: {},
      2: {},
      5: {},
    },
    timeslots: undefined,
  }
}
