Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

// this is duplicated somewhere...
var Address = function Address() {
  _classCallCheck(this, Address);
};

Address.pc = function (data, marker) {
  var results = [];
  for (var i = 0; i < data.length; i++) {
    var rowTrim = void 0;
    var row = data[i].split(',');
    for (var j = 0; j < row.length; j++) {
      rowTrim = row[j].trim();
      var hasProv = rowTrim.indexOf(marker) > -1;
      var hasPostal = rowTrim.split(' ').length > 1;
      if (rowTrim.length > 1 && hasProv && hasPostal) {
        results.push(rowTrim.split(marker)[1]);
      }
    }
  }
  var postalCode = results.reduce(function (a, b) {
    return a.length > b.length ? a : b;
  });
  return postalCode;
};

exports.default = Address;