import axios from 'axios'
import * as A from '../actions'
import to from 'await-to-js'
import { Express } from '../../config'
import HttpStatus from 'http-status-codes'

// TODO use progress
// THIS IS CLEARLY AN ADMIN ENDPOINT
// use axAdmin
export default async(dispatch) => {
  const [e, resp] = await to(
    axios.get(Express.activeShops)
  )
  if (e) {
    console.log(e)
    let status = HttpStatus.INTERNAL_SERVER_ERROR
    if (e.response && e.response.status) {
      status = e.response.status
    }
    dispatch({
      type: A.main.craftStatus,
      value: status
    })
    return
  }
  dispatch({
    type: A.bottleshop.setAll,
    value: resp.data
  })
  dispatch({
    type: A.main.craftStatus,
    value: resp.status
  })
}
