const lookup = {
  ottawa: [5],
  toronto: [23, 24, 26, 62],
  waterloo: [2],
}

export default function subdomain() {
  const { hostname } = window.location
  if (hostname.startsWith('toronto.')) {
    return ['toronto', lookup.toronto]
  } else if (hostname.startsWith('ottawa.')) {
    return ['ottawa', lookup.ottawa]
  } else if (hostname.startsWith('waterloo')) {
    return ['waterloo', lookup.waterloo]
  } else {
    return [null]
  }
}