import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { blueGrey } from '@material-ui/core/colors'
import { Agree } from '../icons'

const Checkout = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blueGrey[500]),
    borderColor: blueGrey[400],
    '&:hover': {
      borderColor: blueGrey[900],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: blueGrey[50],
      color: blueGrey['A400'],
    }
  },
  text: {
    color: blueGrey[500],
    fontStyle: 'courrier',
    fontWeight: 'bold',
    letterSpacing: 2,
    textTransform: 'none',
  }
}))

export default function GotIt(props) {
  const classes = useStyles()

  return (
    <Checkout
      className={classes.button}
      variant='outlined'
      aria-label='close modal'
      {...props}
    >
      <span className={classes.text}>
        <Agree L color={blueGrey['A700']} />
        Got It!
      </span>
    </Checkout>
  )
}
