export default (tokenExp) => {
  const now = Math.floor(Date.now() / 1000)
  if (!tokenExp) {
    return false
  }
  if(tokenExp < now) {
    return false
  }
  return true
}
