import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

const Ok = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    borderColor: green[400],
    '&:hover': {
      borderColor: green[1700],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  text: {
    color: green[500],
    fontWeight: 'bold'
  }
}))

export default function OkBtn(props) {
  const classes = useStyles()

  return <Ok {...props}>
    <span className={classes.text}>
      {props.children}
    </span>
  </Ok>
}