import { StripeStatus } from '../@craft-delivery/cfg/dist/config'

// Stripe charge types color helper
export default refundType => {
  const { charge } = StripeStatus
  switch (refundType) {
    case charge.ok:
      return 'green'
    case charge.pending:
      return 'orange'
    default:
      return 'red'
  }
}