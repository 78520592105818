import init from './init/lookups'
import actions from './actions/lookups'

export default (state=init, action) => {
  switch (action.type) {
    case actions.cTables: {
      return {
        ...state,
        lookups: {
          ...state.lookups,
          cTables: action.data,
        }
      }
    }
    case actions.init: {
      const idtocat = {}
      const catEntries = Object.entries(action.data.cat)
        .map(ce => {
          const catKey = ce[1].toLowerCase()
          const catVal =  parseInt(ce[0], 10)
          idtocat[catVal] = catKey
          return [
            catKey,
            catVal,
          ]
        })
        // .map(ce => ([ce[1].toLowerCase(), parseInt(ce[0], 10)]))
      
      // const cattoid = Object.fromEntries(catEntries)

      const cattoid = {}
      catEntries.forEach(entry => {
        cattoid[entry[0]] = entry[1]
      })
      return {
        ...state,
        lookups: {
          ...state.lookups,
          ...action.data,
          cattoid,
          idtocat,
        }
      }
    }
    // formerly byCat
    // removing a lot of catid nesting
    case actions.lookups2: {
      const { payload } = action
      const {
        cat2Ids,
        country,
        origin,
        producer,
        varietal,
        varietals,
      } = payload
      return {
        ...state,
        lookups: {
          ...state.lookups,
          cat2Ids,
          country,
          origin,
          producer,
          varietal,
          varietals,
        }
      }
    }
    default: {
      if (action.type.startsWith('lookups')) {
        console.warn('lookups reducer default')
      }
      return state
    }
  }
}
