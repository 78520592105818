import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  session: {
    authId: ActionType,
    editPhoneModal: ActionType,
    setPhone: ActionType,
    resetPhoneField: ActionType,
    setPhoneField: ActionType,
    setPhoneVer: ActionType,
    setPhoneUpdating: ActionType,
    loginUser: ActionType,  // new!
    logout: ActionType,
	  stay: ActionType,
	  agreetos: ActionType,
	  resettos: ActionType,
	  setEmailIsVerified: ActionType,
    hasAddress: ActionType,
    pingIp: ActionType,
    telVerify: ActionType,
    failedLogin: ActionType,
  }
})

const _actions = actions.session
export default _actions
