Object.defineProperty(exports, "__esModule", {
  value: true
});

var _padLeft = require('pad-left');

var _padLeft2 = _interopRequireDefault(_padLeft);

var _accounting = require('accounting');

var _accounting2 = _interopRequireDefault(_accounting);

var _lodash = require('lodash.replace');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } } /* eslint-disable no-useless-escape */


var space = '\xA0\xA0';

var StringHelpers = function StringHelpers() {
  _classCallCheck(this, StringHelpers);
};
/* eslint-enable */


StringHelpers.hyphenate = function (phone) {
  if (typeof phone === 'number') {
    phone = phone.toString();
  }
  phone = phone.trim();

  if (phone.length !== 10) {
    return phone;
  }
  phone = StringHelpers.onlyNum(phone);
  phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  return phone;
};

StringHelpers.ul = function (items) {
  return items.map(function (i) {
    return '&#8226; ' + i + ' <br /><br />';
  });
};

StringHelpers.alphify = function (s) {
  var alpha_and_space = s.replace(/[^A-Za-z ]/g, '');
  var single_spaced = alpha_and_space.replace(/ +/g, ' ');
  return single_spaced;
};

StringHelpers.slugify = function (s) {
  if (!s) {
    return '';
  }
  var alpha = StringHelpers.alphify(s.trim());
  return alpha.toLowerCase().replace(/\s+/g, '-');
};

StringHelpers.nosql = function (s) {
  return s.replace(/[\t\r\n]|(--[^\r\n]*)|(\/\*[\w\W]*?(?=\*)\*\/)/gi, '').replace(/ +/g, '').replace(/\//g, '').replace(/\\/g, '');
};

StringHelpers.no_special = function (s) {
  return s.replace(/[*[\]|&;$%"<#>=()'",!`?^:}{]/g, '');
};

StringHelpers.clean = function (s) {
  return s.replace(/[\t\r\n]|(--[^\r\n]*)|(\/\*[\w\W]*?(?=\*)\*\/)/gi, '').replace(/[*\[\]|&;$%"<#>=()'\",!`?^:}{]/g, '').replace(/ +/g, '').replace(/\//g, '').replace(/\\/g, '');
};

StringHelpers.none = function (s) {
  return s;
};

StringHelpers.onlyNum = function (s) {
  var log = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  var cleaned = '';
  var badChars = /\.|\+|\-/g;
  (0, _lodash2.default)(s, badChars, '');

  cleaned = s.replace(/[^0-9]+/g, '');
  cleaned = cleaned.replace(/E|e/g, '');

  if (log) {
    console.log(cleaned);
  }
  return cleaned;
};

StringHelpers.padArray = function (a) {
  var maxLen = 0;
  var output = [];
  a.forEach(function (s) {
    if (s.length > maxLen) {
      maxLen = s.length;
    }
  });
  a.forEach(function (s) {
    output.push((0, _padLeft2.default)(s, maxLen, space));
  });
  return output;
};

StringHelpers.padStr = function (s, maxLen) {
  return (0, _padLeft2.default)(s, maxLen, space);
};

StringHelpers.padFixed = function (s, maxLen) {
  var toFixed = _accounting2.default.toFixed(s, 2);
  var padded = StringHelpers.padStr(toFixed, maxLen);
  return padded;
};

StringHelpers.capsNoSpaces = function (s) {
  return StringHelpers.noSpaces(s).toUpperCase();
};

StringHelpers.noSpaces = function (s) {
  return s.replace(/ /g, '');
};

StringHelpers.capWords = function (s) {
  var toLower = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  if (!s) {
    return '';
  }
  if (toLower) {
    s = s.toLowerCase();
  }
  return s.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
};

StringHelpers.roundFloat = function (n) {
  var floatString = _accounting2.default.toFixed(n, 2);
  var floatNumber = parseFloat(floatString);
  return floatNumber;
};

StringHelpers.tryParseFloat = function (n) {
  var f = parseFloat(n);
  if (isNaN(f)) {
    throw true;
  }
  return f;
};

StringHelpers.niceDate = function (date, keepTimeZone) {
  var timezone = '';
  var dateString = date.toString();

  var niceDate = dateString.split(' GMT')[0];

  // account for 

  if (keepTimeZone) {
    timezone = dateString.match(/\(([A-Za-z\s].*)\)/)[1];
    niceDate = niceDate + ' ' + timezone;
  }
  return niceDate;
};

StringHelpers.niceDateFromTimestamp = function (timestamp, keepTimeZone) {
  var date = new Date(parseFloat(timestamp) * 1000);
  return StringHelpers.niceDate(date, keepTimeZone);
};

exports.default = StringHelpers;