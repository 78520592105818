export default {
  primary: {
    color: '#3f51b5',
    light: '#757de8',
    dark: '#002984',
  },
  secondary: {
    color: '#cfd8dc'
  },
  // These are bootstrap css values
  textDanger: '#f86c6b',
  textInfo: '#63c2de',
  textSuccess: '#4dbd74',
  textWarning: '#f8cb00'
}