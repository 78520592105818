import * as A from '../actions'

// TODO ADD ACTION POINTS...
export default (dispatch) => {
  const now = Date.now()
  let midnight = new Date()
  midnight.setHours(24)
  midnight.setMinutes(0)
  midnight.setSeconds(0)
  const ticksToMidnight = midnight.getTime() - now
  //console.log(ticksToMidnight / 1000 / 60 / 60)
  dispatch({
    type: A.time.loginTime,
    value: now,
  })
  dispatch({
    type: A.time.ticksToMidnight,
    value: ticksToMidnight,
  })

  // trigger dispatch at 2 seconds to midnight
  setTimeout(() => {
    // TODO: RESET ANYTHING THAT WAITS TILL THE NEXT DAY
    console.log('MIDNIGHT ALERT!')
    dispatch({
      type: A.time.isMidnight,
      value: true
    })
  }, ticksToMidnight - 2000)
}
