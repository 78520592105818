import React from 'react'
import { Cog } from './icons'
import MainDiv from './maindiv'

export default props =>
  <MainDiv>
    <Cog show={true} D grey />
    {
      !props.text ? null : (
        <span>
          {props.text}
        </span>
      )
    }

  </MainDiv>