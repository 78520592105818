import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { orange } from '@material-ui/core/colors'
import Icon from '../icon'

const Warn = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    borderColor: orange[400],
    '&:hover': {
      borderColor: orange[1700],
    },
  },
}))(Button)

// must be outlined for this to work
const useStyles = makeStyles(() => ({
  text: {
    color: orange[500],
    fontWeight: 'bold'
  }
}))

export default function Remove(props) {
  const classes = useStyles()
  const {
    content,
    icon
  } = props
  return (
    <Warn variant='outlined' {...props}>
      <Icon
        icon={icon ? icon : 'close'}
        padRight={2}
        large
      />
      <span className={classes.text}>
        {content ? content : 'Remove'}
      </span>
    </Warn>
  )
}