const simpleCartInitialState = {
  simplecart: {
    craft: {},
    // an order is either cc or email transfer
    isCc: false,
    lcbo: {},
    lcboqty: {},
    lcboCount: 0,
    stripeToken: undefined,
    timeslot: undefined,
    properIdSeen: false,
  }
}

export default simpleCartInitialState
