const init = {
  main: {
    drawerOpen: false,
    locOpen: false,
    showHoursLegend: false,
    showHoursDialog: false,
    hoursDialogShopId: null,
    hoursDialogColor: null,
    hoursDialogText: null,
    hash: '',
    previousRoute: '',
    routeToAfterLogin: undefined,
    previousTab: '1',
    currentTab: '2',
    previousChoice: undefined,
    showPopupTester: true,
    infoFontSize:  19,
    lcboStatus: 400,
    craftStatus: 400,
    device: {
      h: null,
      iOS: null,
      iPhone: null,
      isBot: null,
      isMobile: null,
      isPortrait: null,
      maxWidth: null,
      mobileDetectIsMobile: null,
      os: null,
      pad: 5,
      padr: 0.4,
      phone: null,
      small: null,
      smallWidth: true, // mobile first then expand
      largeWidth: null,
      tablet: null,
      userAgent: null,
      userAgentFull: null,
      w: 500,  // mobile first then expand
    },
  }
}

// IMPORTANT CALL as Function
const getNewAuthForm = () => {
  return Object.assign({}, init.main.auth)
}
export default init
export { getNewAuthForm }
