import { StringHelpers } from '../@craft-delivery/cfg/dist/util'

export default invoice => {
  return {
    lcboTotal: StringHelpers.padFixed(invoice.lcboTotal, 2),
    stripe: StringHelpers.padFixed(invoice.stripe, 2),
    total: StringHelpers.padFixed(invoice.total, 2),
    craftTotal: StringHelpers.padFixed(invoice.craftTotal + invoice.craftHst, 2),
    craftSub: StringHelpers.padFixed(invoice.craftTotal, 2),
    craftHst: StringHelpers.padFixed(invoice.craftHst, 2),
    delivery: StringHelpers.padFixed(invoice.delivery, 2),
    deliveryHst: StringHelpers.padFixed(invoice.deliveryHst, 2),
    deliveryTotal: StringHelpers.padFixed(invoice.delivery + invoice.deliveryHst, 2),
  }
}