import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  history: {
    routes: ActionType,
  }
})
const _actions = actions.history

export default _actions
