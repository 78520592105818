import { ActionType, createActionTypes } from 'action-types'

// ticksTo is last call for various retail types
const actions = createActionTypes({
  time: {
    loginTime: ActionType,
    isMidnight: ActionType,
    ticksToMidnight: ActionType,
    ticksToLcbo: ActionType,
    ticksToBeerStore: ActionType,
    ticksToCraft: ActionType,

    // test
    ticksToNextHour: ActionType,
  }
})
const timeActions = actions.time
const timeValues = Object.values(timeActions)
export default timeActions
export { timeValues }