import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  lookups: {
    cTables: ActionType,
    init: ActionType,
    lookups2: ActionType, // replaces byCat
  }
})

const _actions = actions.lookups
export default _actions