import init from './init/address'
import actions from './actions/address'

export default (state=init, action) => {
  switch (action.type) {
    case actions.loadData: {
      return {
        ...state,
        address: {
          ...state.address,
          primaryId: action.addressPrimaryId,
          addresses: action.addresses,
        }
      }
    }
    case actions.update: {
      return {
        ...state,
        address: {
          ...state.address,
          addresses: {
            ...state.addresses,
            [action.address.id]: action.address
          }
        },
      }
    }
    // TBD do we need this?
    case actions.clearData: {
      return init
    }
    default: {
      if (action.type.startsWith('address')) {
        console.warn('address reducer default')
      }
      return state
    }
  }
}
