import React from 'react'
import { Link } from 'react-router-dom'
import { Ok } from '../buttons'

export default function Explore({
  text='Start Exploring',
}) {
  return (
    <Link to='/home'>
    <Ok variant='outlined' color='primary'>
      {text}
    </Ok>
  </Link>
  )
}