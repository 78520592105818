import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  actions as A,
  getSelector,
} from '../reducer'

const getCat = (pname, hash) => {
  const csplit = pname.split(hash)
  if (csplit && csplit.length) {
    return csplit.length === 0 ? csplit[0] : csplit[1]
  }
}

// TDB: do we want to start stashing relevant histories here?
//      ie specific products
export default function HistoryHelper() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { cattoid } = getSelector('lookups')

  useEffect(() => {
    const removeH = history.listen((h) => {
      dispatch({
        type: A.history.routes,
        route: h.pathname,
      })
      const lcboHash = '/lcbo/'
      const saleHash = '/sale/'
      let rCat, category, catid
      let validRoute
      if (h.pathname) {
        if (h.pathname.startsWith(lcboHash)) {
          rCat = h.pathname.replace(lcboHash, '')
          category = getCat(h.pathname, lcboHash)
        } else if (h.pathname.startsWith(saleHash)) {
          category = h.pathname.split(saleHash)
          category = getCat(h.pathname, saleHash)
          rCat = h.pathname.replace(saleHash, '')
        } else {
          validRoute = false
        }
        if (validRoute) {
          catid = cattoid[category]
          
          if (category && catid) {
            dispatch({
              type: A.lcbobeta.catId,
              category: rCat,
              catId: catid
            })
          } else {
            dispatch({
              type: A.lcbobeta.setCategory,
              value: rCat,
            })

          }
        }
      }
    })
    return removeH
  }, [
    cattoid,
    dispatch,
    history,
  ])

  return null
}