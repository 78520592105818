import validKey from './validkey'

const getItem = (key, parse, bool=false) => {
  if (!validKey(key, 'GET ITEM')) {
    return false
  }
  const item = localStorage.getItem(key)
  if (parse) {
    try {
      const o = JSON.parse(item)
      if (o && typeof(o) === 'object') return o
    } catch (e) {
      console.warn(`GET ITEM: could not parse item for key: ${key}`)
      return false
    }
  }
  if (bool) {
    return item === 'true' ? true : false
  }
  return item
}
export default getItem

export let getBool = key => getItem(key, false, true)