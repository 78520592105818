import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import { Signin } from '../icons'

const Login = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    borderColor: blue[400],
    '&:hover': {
      borderColor: blue[900],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: blue[50],
      color: blue['A400'],
    }
  },
  link: {
    textDecorationColor: blue[200],
  },
  text: {
    color: blue[500],
    fontWeight: 'bold',
    textTransform: 'none',
  },

}))

export default function LoginBtn(props) {
  const classes = useStyles()

  return (
    <Link to='/login' className={classes.link}>
      <Login
        className={`${classes.button} checkout-button`}
        variant='outlined'
        {...props}
      >
        <span className={classes.text}>
          <Signin L color={blue['A700']} />
          Login
        </span>
      </Login>
    </Link>
  )
}