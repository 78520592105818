import axios from 'axios'
import to from 'await-to-js'
import { Express } from '../../config'
import { actions } from '../index'

// call on init as well as region change
export default async function cPreload(dispatch, rid) {
  const url = `${Express.cPreload}/${rid}`
  const [err, resp] = await to(axios.get(url))

  if (err) {
    console.log(err)
    return
  }

  const { shopUrls, ...preload } = resp.data
  dispatch({
    type: actions.bottleshop.preload,
    preload,
    rid,
  })

  dispatch({
    type: actions.bottleshop.shopUrls,
    shopUrls,
  })

}