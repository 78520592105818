// NOTE this is an enum:
//      key and value must be the same!
export default {
  adminloc: 'adminloc',
  // auth: 'auth',

  // deprecate individual auth keys:
  emailAuth: 'emailAuth',
  emailConf: 'emailConf',
  emailVer: 'emailVer',
  phoneAuth: 'phoneAuth',
  phoneConf: 'phoneConf',
  phoneVer: 'phoneVer',
  /////////////////////////////////


  cart: 'cart',
  comingSoonEmailSent: 'comingSoonEmailSent',
  comingSoonEmail: 'comingSoonEmail',
  comingSoonName: 'comingSoonName',
  email: 'email',

  geo: 'geo',
  hashLoc: 'hashLoc',
  lastLogin: 'lastLogin',
  ofAge: 'ofAge',
  phone: 'phone',

  manage: 'manage',
  refreshLcboCache: 'refreshLcboCache',
  region: 'region',
  sawBuyDirect: 'sawBuyDirect',
  session: 'session',
  sessionToken: 'sessionToken',
  setActive: 'setActive',
  tabId: 'tabId',
  tabs: 'tabs',

  // todo this should be in sessionStorage
  // if they refresh the page we shouldn't welcome them each time
  welcomeBack: 'welcomeBack',
}