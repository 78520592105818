// local storage set handler
import validKey from './validkey'

export default (key, value) => {
  const label = 'SET ITEM'
  if (!validKey(key, label, value)) {
    console.log('invalid key: setItem')
    return false
  }
  // true or false will be converted to 'true' or 'false'
  // we dont want undefined or null to be stored,

  // trigger warning
  if (value === undefined || value === null) {
    console.group(label)
    console.warn('use localStorage clear to remove a value. undefined value for key:')
    console.warn(key)
    console.groupEnd(label)
    return
  }
  if (typeof(value) === 'object') {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    localStorage.setItem(key, value)
  }
  return true
}