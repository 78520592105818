import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  img: {
    beers: ActionType,
    urlRoot: ActionType,
  }
})
const _actions = actions.img
const imgValues = Object.values(_actions)
export default _actions
export { imgValues }
