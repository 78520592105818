import React, { PureComponent, lazy } from 'react'
import { connect } from 'react-redux'
import { getItem } from '../utility'
import {
  initMain,
  getReducers,
  pingIp,
  signinSession,
  actions as A,
} from '../reducer'
import Routes from './routes/routes'
import {
  storageKeys as keys,
} from '../config'
import Beerify from './beerify'
import PopTart from './poptart'
import NoFallback from './nofallback'
import HistoryHelper from './historyhelper'
import LcboDataHelper from './lcbo/datahelper'

const BottomNav = lazy(() => import('./bottomnav'))
const Header = lazy(() => import('./header'))
const NeedInfo = lazy(() => import('./alerts/needinfo'))
const NotVerified = lazy(() => import('./alerts/notverified'))
const Drawer = lazy(() => import('./drawer'))

const drawerWidth = 180
const styles = {
  content: {
    flexGrow: 1,
    marginLeft: 0,
  },
  shifted: {
    marginLeft: drawerWidth,
    overflowY: 'hidden',
  },
}

class Main extends PureComponent {
  state = {
    triggerUpdate: null,
    leftSwipe: false
  }
  componentDidMount = async() => {
    this.props.pingIp()
    this.props.initMain()
    this.props.signinSession()

    const sawBuyDirect = getItem(keys.sawBuyDirect, false, true)
    if (sawBuyDirect) {
      this.props.hideBuyDirect()
    }
    // spin out into utility function... checkLastLogin...
    const lastLogin = getItem(keys.lastLogin)
    const lastLoginInt = parseInt(lastLogin)
    if (!Number.isNaN(lastLoginInt)) {
      const deltaSeconds = (Date.now() - lastLoginInt) / 1000
      // warn if last login was more than 30 minutes ago
      // TODO determine why app hangs on index.html spinner
      const MAX_TIME = 60 * 30
      if (deltaSeconds > MAX_TIME) {
        console.warn('OLD SESSION > ' + MAX_TIME)
      }
    }
  }

  render() {
    const isLoggedIn = this.state.isLoggedIn > 0
    const { main, session } = this.props
    const {
      emailVerified,
      hasAddress,
    } = session
    const { drawerOpen } = main

    return (
      <div>
        <HistoryHelper />
        <LcboDataHelper />
        <NoFallback>
          <Drawer />
        </NoFallback>
        <div
          className='app'
          style={!drawerOpen ? styles.content : styles.shifted}
        >
          <PopTart />
          <Beerify />
          <NoFallback>
            <Header />
          </NoFallback>
          <div className='app-body'>
            <main className='main'>
              {
                !emailVerified && isLoggedIn ? (
                  <NoFallback>
                    <NotVerified />
                  </NoFallback>
                ) : null
              }
              {
                emailVerified && isLoggedIn && !hasAddress ? (
                  <NoFallback>
                    <NeedInfo />
                  </NoFallback>
                ) : null
              }
              <Routes />
            </main>
          </div>
        </div>
        <NoFallback>
          <BottomNav />
        </NoFallback>
      </div>
    )
  }
}
const reducers = [
  'main',
  'session',
]
export default connect(
  s => getReducers(s, reducers),
  d => {
    return {
      initMain: () => initMain(d),
      pingIp: () => pingIp(d),
      signinSession: () => signinSession(d),
      hideBuyDirect: () => d({
        type: A.bottleshop.showBuyDirectInfo,
        value: false,
      })
    }
  },
)(Main)
