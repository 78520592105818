/*
[
  ...
  snack: {
      message,
      duration,
      variant,
    }
]
*/
export default {
  poptart: {
    snacks: [],
  }
}
