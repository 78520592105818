import screen from './screen'

const MIN_WIDTH = 440
const MAX_NAME = 12

// TBD: because the width in redux is updated by window events,
//      it changes. We may need to tap into it if really necessary
export default shop => {
  const { name, name_short } = shop
  if (!shop.use_name_short) return name
  const longName = name.length > MAX_NAME
  const smallScreen = screen.w < MIN_WIDTH
  const useShort = smallScreen || longName
  if (useShort) return name_short
  return name
}
