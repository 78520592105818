import axios from 'axios'
import to from 'await-to-js'
import { Lambda } from '../../config'
import * as A from '../actions'

// will now download inv for region unless its older than half an hour
export default async(dispatch, regions, regionId=undefined) => {
  let id = regionId || regions.defaultId
  if (regions.currentRegion) {
    id = regions.currentRegion.id
  }
  const now = new Date().getTime()
  const freshStamp = regions.invFresh[id]

  // 30 min (delta must be divided by 1000)
  const freshTime = 60 * 30
  const delta = (now - freshStamp) / 1000
  const stillFresh = delta < freshTime

  if (freshStamp && stillFresh) return

  const [err] = await to(
    axios.get(`${Lambda.invRegional}/${id}`)
  )
  if (err) {
    console.log(err)
    return
  }
  dispatch({
    type: A.regions.invFresh,
    regionId: id,
  })
}
