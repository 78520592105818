import React from 'react'
import BaseIcon from './baseicon'

export default function SignupIcon(props) {
  return props.hide ?
    null :
    <BaseIcon
      {...props}
      icon='signup'
    />
}