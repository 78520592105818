export default {
  'address': 'address.address',
  'auth': 'auth.auth',
  'bottleshop': 'bottleshop.bottleshop',
  'carts': 'carts.carts',
  'delivery': 'delivery.delivery',
  'history': 'history.history',
  'img': 'img.img',
  'lcbobeta': 'lcbobeta.lcbobeta',
  'lookups': 'lookups.lookups',
  'main': 'reducer.main',
  'manage': 'manage.manage',
  'poptart': 'poptart.poptart',
  'progress': 'progress.progress',
  'regions': 'regions.regions',
  'schedule': 'schedule.schedule',
  'search': 'search.search',
  'cart': 'simpleCart.simplecart',
  'simplecart': 'simpleCart.simplecart',
  'session': 'session.session',
  'time': 'time.time',
}