import * as A from '../actions'
import Stage from '../util/stage'
import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import { Tart } from '../../utility'
import { StatusCodes as http }  from 'http-status-codes'

// todo rename authcheckphone
// TODO check for METHOD_NOT_ALLOWED / REQUEST_URI_TOO_LONG
//      (indicates a plus aliased address)
export default async(dispatch, auth, email, callback) => {
  if (!email) {
    console.log('NO EMAIL')
    return
  }
  const action = 'signupCheck'
  // check local state before duplicating a request
  const {
    // availableComingSoon,
    available,
    unavailable,
  } = A.auth
  const { emailsAvailable, emailsUnavailable } = auth
  // const { enableSocket } = A.session
  if (emailsAvailable.includes(email)) {
    dispatch({ type: available, email })
    return
  }
  if (emailsUnavailable.includes(email)) {
    dispatch({ type: unavailable, email })
    return
  }
  Stage.init(action, dispatch)

  const url = `${Express.signup}/check/email`
  const [err, resp] = await to(
    axios.patch(url, { email })
  )
  if (err) {
    if (err.response?.status === http.CONFLICT) {
      Tart.warn(`${email} is already in use`)
      dispatch({ type: unavailable, email })
    } else {
      Tart.warn('Error Checking Email Availability')
    }
    Stage.err(action, dispatch, err)
    return
  }
  // 200-OK: no user or no coming soon
  // 206-PARTIAL_CONTENT: comingsoon 
  Stage.ok(action, dispatch, resp)
  
  const { data, status } = resp
  if (status === http.OK) {
    dispatch({ type: available, email })
    return
  }
  // TBD: does this actuall work???
  // populate coming soon in reducer
  // const response = dispatch({ type: availableComingSoon, payload: data })
  if (callback) callback(data)
}
