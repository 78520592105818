export default end => {
  const ONE_DAY = 24 * 60 * 60 * 1000
  const now = new Date()
  const endDate = new Date(end)
  const nowTime = now.getTime()
  const endTime = endDate.getTime()
  if (nowTime > endTime) return null
  const delta = nowTime - endTime 
  const abs = Math.abs(delta / ONE_DAY)
  return Math.round(abs)
}