import React from 'react'
import BaseIcon from './baseicon'

export default function AgreeIcon(props) {
  return props.hide ?
    null :
    <BaseIcon
      {...props}
      icon='agree'
    />
}