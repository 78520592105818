import Stage from '../util/stage'
import { Express } from '../../config'
import { axUser } from '../config/ax'
import to from 'await-to-js'
import { Tart } from '../../utility'
import timeToResend from '../util/timetoresend'
 
export default async function authEmailResend(dispatch, authData, progress) {
  if (progress) {
    const resend = timeToResend(progress.timestamp, 'resending confirmation email')
    if (!resend) return
  }
  const action = 'signupEmailResend'
  Stage.init(action, dispatch)
  const url = Express.email
  const { email, emailConf } = authData
  const auth = {
    email,
    emailConf,
  }
  const [err, resp] = await to(
    axUser.patch(url, { auth })
  )
  if (err) {
    console.log(err)
    Tart.warn('Error Resending Email')
    Stage.err(action, dispatch, err)
    return
  }
  Stage.ok(action, dispatch, resp)
  Tart.ok('Email resent!')
}
