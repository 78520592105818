import {
  DepositDetails,
  InvoiceDetails,
} from '../@craft-delivery/cfg/dist/invoice'

import { StringHelpers } from '../@craft-delivery/cfg/dist/util'

// TODO / TBD
// not being used. spin out anything useful into its own function
export default class BeerUtils {
  static lcboTax = (total) => {
    const hst = InvoiceDetails.hstFactor
    return StringHelpers.roundFloat(total - total / hst)
  }

  /*
  http://hellolcbo.com/app/answers/detail/a_id/196/~/bagitback%3A-bottle-return-rates
  Containers less than or equal to 630mL = 10¢ deposit refund
  Containers over 630mL = 20¢ deposit refund

  Aluminum and steel containers less than or equal to 1L = 10¢ deposit refund
  Aluminum and steel containers over 1L = 20¢ deposit refund
  */
  static deposit = item => {
    let deposit = 0, multiplier = 1
    const multChar = 'x'
    const {
      packagetype,
      packagedesc,
      volml,
    } = item
    const {
      depSmall,
      depLarge,
      volSmall,
      volLarge
    } = DepositDetails
    const validTypes = [ 'can', 'bottle', 'bagnbox', 'tetra']
    const secondChar = packagedesc[1] === multChar
    const thirdChar =  packagedesc[2] === multChar

    if (secondChar) {
      // 4 or 6 packs
      multiplier = parseInt(packagedesc[0], 10)
    } else if (thirdChar) {
      // 12 or 24
      multiplier = parseInt(packagedesc.substr(0,2), 10)
    } else {
      multiplier = 1
    }
    const invalidType = !validTypes.includes(packagetype)
    if (!packagetype || invalidType) {
      return 0
    }

    // we need the volume per unit, not for the total, so divide volML by multiplier
    if (packagetype === 'can') {
      if (volml / multiplier > volLarge) {
        deposit = depLarge * multiplier
      } else {
        deposit = depSmall * multiplier
      }
    } else {
      if (volml / multiplier > volSmall) {
        deposit = depLarge * multiplier
      }
      else {
        deposit = depSmall * multiplier
      }
    }
    return StringHelpers.roundFloat(deposit)
  }

  static lineTotal = (qty, priceCents) => {
    const lt = qty * (priceCents / 100 )
    return  StringHelpers.roundFloat(lt)
  }

  static singleItemTotal = (priceCents) => {
    return BeerUtils.lineTotal(1, priceCents)
  }
}
