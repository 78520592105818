import { ActionType, createActionTypes } from 'action-types'

// todo come up with a naming schema
// should be named exactly as the reducer/rest filename
const actions = createActionTypes({
  progress: {
    addressSubmit: ActionType,
    cartSave: ActionType,
    comingSoon: ActionType,
    comingSoonConfirm: ActionType,
    comingSoonUnsub: ActionType,
    // daysOpen may be too vague if other ops come into play
    daysGet: ActionType,
    daysOpen: ActionType,
    dropdowns: ActionType,
    emailCheckout: ActionType,
    emailResendConf: ActionType,
    getInventory: ActionType,
    getOrders: ActionType,
    lcbo: ActionType,
    
    orderInfo: ActionType,
    signin: ActionType,
    signinSession: ActionType,
    signinToken: ActionType,
    signup: ActionType,// tbd: do we need this
    signupUser: ActionType,
    signupCheck: ActionType,
    signupEmail: ActionType,
    signupConfirmEmail: ActionType,
    signupEmailResend: ActionType,
    signupPhone: ActionType,
    signupCheckPhone: ActionType,
    stripeCharge: ActionType,
    stripeRefund: ActionType,
    submitPhone: ActionType,
    telVerify: ActionType,
    unsub: ActionType,
  }
})

const _actions = actions.progress
const progressActionValues = Object.values(_actions)
export default _actions
export { progressActionValues }
