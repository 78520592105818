import axios from 'axios'
import to from 'await-to-js'
import {
  getItem,
  storageKeys as keys,
  Tart
} from '../../utility'
import Stage from '../util/stage'
import { Express } from '../../config'
import { StatusCodes as http } from 'http-status-codes'
// import clearLocal from '../../utility/clearlocal'
import {
  actions as A,
 } from '../index'
import signinFinal from './signinfinal'

export default async(dispatch) => {
  const sessionToken = getItem(keys.sessionToken)
  if (!sessionToken) return null
  // dispatch({ type: A.auth.clear.all })

  const action = 'signinSession'
  Stage.init(action, dispatch)
  
  const url = Express.signin
  const [err, resp] = await to(axios.put(url, { sessionToken }))
  
  if (err) {
    if (err.response) {
      const { status } = err.response
      if (status === http.UNPROCESSABLE_ENTITY) {
        Tart.info('Your session has expired. Log back in or keep browsing. No pressure')
        localStorage.removeItem(keys.sessionToken)
        // clearLocal()
        
        dispatch({ type: A.address.clearData })
      } else {
        console.log(err)
      }
    }
    Stage.err(action, dispatch, err)
    return
  }
  Stage.ok(action, dispatch, resp)

  const { data } = resp
  signinFinal(dispatch, data)
}