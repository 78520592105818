import phoneNormalize from './phonenormalize'
import { constants } from '../config'
import codes from '../@craft-delivery/cfg/dist/config/areacodes'
import bs from './bs'

const { TEL_LEN } = constants

const checkAgainst = (area) => {
  const intArea = parseInt(area, 10)
  const isBad = bs(codes.bad, intArea)
  if (isBad) {
    return {
      error: 'No Network Admin Numbers',
      valid: false,
    }
  }
  const na = bs(codes.northAmerica, intArea)
  if (!na) {
    return {
      error: 'North American Numbers Only',
      valid: false,
    }
  }
  return null
}

export default function phoneVal(number) {
  let phoneNumber = phoneNormalize(number)
  if (!phoneNumber) {
    return {
      error: 'Invalid Number',
      valid: false,
    }
  }

  let numberLen = phoneNumber.length
  if (numberLen < TEL_LEN) {
    return {
      error: 'Too Short',
      valid: false,
    }
  }

  const first3 = phoneNumber.substr(0,3)
  const next3 = phoneNumber.substr(3, 3)
  if (first3 === '555' || next3 === '555' || first3.startsWith('1')) {
    return {
      error: 'Bogus Number',
      valid: false,
    }
  }

  const check = checkAgainst(first3)
  if (check) return check
  return {
    error: null,
    valid: true,
  }
}
