export default {
  history: {
    routes: [],
    loc: '/home',
    // is: {
    //   account: false,
    //   admin: false,
    //   cart: false,
    //   checkout: false,
    //   craft: false,
    //   getPaid: false,
    //   helpContact: false,
    //   helpEmerg: false,
    //   helpFaq: false,
    //   helpHours: false,
    //   helpTerms: false,
    //   helpReturns: false,
    //   helpPrivacy: false,
    //   home: true,
    //   lcbo: false,
    //   login: false,
    //   manageShops: false,
    //   orders: false,
    //   sigout: false,
    //   signup: false,
    // },
  }
}