import phoneFormatter from 'phone-formatter'
import { constants } from '../config'

const { TEL_LEN } = constants

// use this to validate numbers
export default function phoneNormalize(s) {
  const normalized = phoneFormatter.normalize(String(s))
  const pint = parseInt(normalized, TEL_LEN)
  const spint = Number.isNaN(pint) ? null : String(pint)
  return spint
}