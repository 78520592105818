const init = {
  bottleshop: {
    preload: {},
    shopIdsBySlug: null,
    sortOrder: null,
    units: null,
    unitDetailsById: null,
    sortedShopIds: null,
    shopUrls: null,
    showBuyDirectInfo: true,
  }
}
export { init }
