import React from 'react'
import Icon from '../icon'
import getColor from './getcolor'

export default function CogIgon(props) {
  if (!props.show) return null
  const {
    double,
    D,
    large,
    L,
    triple,
    T,
    left,
    right,
  } = props
  return (
    <Icon
      icon='loading.cog'
      double={double || D ? true : false}
      large={large || L ? true : false}
      triple={triple || T ? true : false}
      padLeft={left || 0}
      padRight={right || 2}
      color={getColor(props)}
    />
  )
}
