import timeInitialState from './init/time'
import { timeValues } from './actions/time'

// generic reducer
export default (state=timeInitialState, action) => {
  const actionType = action.type.replace('time.', '')
  if (timeValues.includes(action.type)) {
    return {
      ...state,
      time: {
        ...state.time,
        [actionType]: action.value,
      }
    }
  }
  return state
}

export { timeValues }
