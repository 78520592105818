import init from './init/main'
import actions, { mainValues } from './actions/main'
import {
  setItem,
  storageKeys as keys,
} from '../utility'


export default (state=init, action) => {
  switch (action.type) {
    case actions.drawerToggle: {
      return {
        ...state,
        main: {
          ...state.main,
          drawerOpen: !state.main.drawerOpen,
        }
      }
    }
    case actions.hash: {
      const { hash } = action
      setItem(keys.hashLoc, hash)
      return {
        ...state,
        main: {
          ...state.main,
          hash,
        }
      }
    }
    case actions.toggleHoursLegend: {
      return {
        ...state,
        main: {
          ...state.main,
          toggleHoursLegend: !state.main.showHoursLegend,
        }
      }
    } case actions.locOpen: {
      return {
        ...state,
        main: {
          ...state.main,
          locOpen: true,
        }
      }
    } case actions.locClose: {
      return {
        ...state,
        main: {
          ...state.main,
          locOpen: false,
        }
      }
    } case actions.device: {
      // log once
      // this is nice to see in logrocket when we test on a mobile device
      if (!state.main.device.logged) {
        const {
          h,
          w,
          isMobile,
        } = action.value
        console.log({h, w, isMobile})
      }
      return {
        ...state,
        main: {
          ...state.main,
          device: {
            ...state.main.device,
            ...action.value,
            logged: true,
          }
        }
      }
    }
    case actions.hideHoursDialog: {
      return {
        ...state,
        main: {
          ...state.main,
          showHoursDialog: false,
          hoursDialogShopId: undefined,
          hoursDialogHours: undefined,
          hoursDialogColor: null,
        }
      }
    }
    case actions.showHoursDialog: {
      return {
        ...state,
        main: {
          ...state.main,
          showHoursDialog: true,
          hoursDialogShopId: action.sid,
          hoursDialogHours: action.hours,
          hoursDialogColor: action.color,
        }
      }
    }

    // fallback
    default: {
      if (mainValues.includes(action.type)) {
        let a = action.type.replace('main.', '')
        return {
          ...state,
          main: {
            ...state.main,
            [a]: action.value,
          }
        }
      }
      return state
    }
  }
}
