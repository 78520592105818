import axios from 'axios'
import to from 'await-to-js'
import md5 from 'js-md5'
import { Tart } from '../../utility'
import Stage from '../util/stage'
import { Express } from '../../config'
import * as A from '../actions'
import signinFinal from './signinfinal'

// use callback to trigger history push hook,
// get rid of routeTo...
// signin must be converted for a fn component
//
export default async(dispatch, auth, cb) => {
  // dispatch({type: A.auth.clear.all})
  const action = 'signin'
  Stage.init(action, dispatch)
  const { email, pwd } = auth
  if (!email || !email.length) {
    const { resetLogin } = A.main
    const msg = 'Invalid or missing email'
    Tart.err(msg)
    Stage.fail(action, dispatch, msg)
    dispatch({ type: resetLogin })
    return
  }
  const [err, resp] = await to(
    axios.post(
      Express.signin, {
        pwd: md5(pwd),
        email: email.trim().toLowerCase(),
      }
    )
  )
  if (err) {
    console.log(err)
    Stage.err(action, dispatch, err)
    Tart.err('Error signing you in')
    dispatch({
      type: A.session.failedLogin,
      value: email,
    })
    if (cb) {
      cb(false)
    }
    return
  }
  Stage.ok(action, dispatch, resp)
  const { data } = resp
  
  signinFinal(dispatch, data)

  if (cb) {
    cb(true, data.user)
  }
}
