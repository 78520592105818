export default {
  // Bloor West
  ACTIVE_LCBO_STORE_TEMP: 149,
  BG_COL: '#fffdff',
  BLOG_LCBO_STORE: 1,
  DEFAULT_ROUTE: '/bottleshops',
  EMAIL_CHECK_PAUSE: 700,

  INPUT_W: 325,
  INTRO_AGCO: 'https://www.agco.ca/alcohol/legal-drinking-age-and-photo-id',
  INTRO_AREYOU: 'Are you 19 years old?',
  INTRO_REDIRECTING: 'Redirecting You to the AGCO...',
  INTRO_THANKS: 'Welcome To Craft Delivery',
  INTRO_DURATION: 3200,
  INTRO_POST_WELCOME: 1000,
  INVITE_MIN_HEIGHT: 640,
  LANDING_SHOW_SHOPS: 740,
  // LANDING_TALLBOYS: 473,
  LARGE_SCREEN: 992,
  LCBO_SM: 234,
  LCBO_MD: 720,
  LCBO_LG: 1280,
  LCBO_MOBILE: 800,
  LOCKOUT: 10 * 1000,      // (10s)
  MAX_AGE: 60 * 5 * 1000, // (5m)
  MAX_STOPS: 6,
  MIN_MOBILE: 500,
  PAD: 4,
  POSTAL_LENGTH: 6,
  PWD_START_OSWAP_CHECK: 1,
  PWD_MIN: 8,
  PWD_MAX: 32,
  PWD_TESTS: 3,
  READ_MORE_SUBSTRING: 125,
  SCAM_BOOZER_PERCENT: 14,
  SCAM_RUNNER_PERCENT: 11,
  SHOW_LABELS: 800,
  SMALL_SCREEN: 480,
  TEL_LEN: 10,
  TEL_MASK: /(\d{3})(\d{3})(\d{4})/,
  TEL_TEMPLATE: '(NNN) NNN-NNNN',
  TIME_TO_RESEND: 75 * 1000, //(1m)
  UPDATED_FAQ: 'Updated 1:16a Dec 28 2020',
  W_PAD: 80,
}