// for orders View
// saved, deleted, authorized, canceled orders/carts
export default {
  carts: {
    list: [],
    summary: undefined,
    orderInfo: undefined,
    orderInfoMsg: null,
  }
}
