import { combineReducers } from 'redux'
import address from '../address'
import auth from '../auth'
import bottleshop from '../bottleshop'
import carts from '../carts'
import delivery from '../delivery'
import history from '../history'
import img from '../img'
import lcbobeta from '../lcbobeta'
import lookups from '../lookups'
import poptart from '../poptart'
import progress from '../progress'
import reducer from '../reducer'
import regions from '../regions'
import search from '../search'
import schedule from '../schedule'
import session from '../session'
import simpleCart from '../simplecart'
import time from '../time'

// TODO inject / remove reducers as needed:
//     use case is for admin
// https://stackoverflow.com/a/33044701

export default combineReducers({
  address,
  auth,
  bottleshop,
  carts,
  delivery,
  img,
  history,
  lcbobeta,
  lookups,
  poptart,
  progress,
  reducer,
  regions,
  search,
  schedule,
  session,
  simpleCart,
  time,
})
