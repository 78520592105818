// we may not need this TBD
const safeDecode = s => {
  try {
    return decodeURIComponent(s)
  }
  catch (e) {
    return unescape(s)
  }
}
export default safeDecode
