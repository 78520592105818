// calculate acceptable time to resend request and notify user
import { Tart } from '../../utility'
import { constants } from '../../config'

const { TIME_TO_RESEND } = constants

export default function timeToResend(ts, msgEnding='resending') {
  if (!ts) return true
  const now = Date.now()
  const elapsed = now - ts
  if (elapsed < TIME_TO_RESEND) {
    const wait = parseInt((TIME_TO_RESEND - elapsed) / 1000, 10)
    Tart.warn(`Please wait about ${wait} seconds before ${msgEnding}`)
    return false
  }
  return true
}