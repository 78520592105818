import { constants } from '../config'

const ret = (ts, lockoutTime) => {
  let seconds = 0
  let delta = Date.now() - ts
  if (Number.isNaN(delta)) {
    return {
      seconds: 0,
      allow: true,
    }
  }
  if (delta < 0) {
    seconds = 0
  } else {
    seconds = (lockoutTime / 1000 - delta / 1000).toFixed(1)
  }
  return {
    seconds,
    allow: delta > lockoutTime,
  }
}

export default {
  lockout: ts => ret(ts, constants.LOCKOUT),
  maxAge: ts => ret (ts, constants.MAX_AGE),
}