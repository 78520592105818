import init from './init/poptart'
import actions from './actions/poptart'

export default (state=init, action) => {
  switch (action.type) {
    case actions.add: {
      return {
        ...state,
        poptart: {
          snacks: [...state.poptart.snacks, action.snack],
        }
      }
    }
    case actions.rm: {
      return {
        ...state,
        poptart: {
          snacks: state.poptart.snacks.filter(s => s.id !== action.id)
        }
      }
    }
    default: {
      if (action.type.startsWith('poptart')) {
        console.warn('poptart reducer default')
      }
      return state
    }
  }
}
