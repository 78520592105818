// use env for comparisons and process.env for values
// prevents errors such as === 1 vs === '1'
// NOTE: ALWAYS COMPARE TO STRING
export default {
  ALLOW_GROWLERS: process.env.REACT_APP_ALLOW_GROWLERS === '1',
  ALLOW_LATE: process.env.REACT_APP_TEST_AFTER_10PM === '1',
  ALLOW_LOCALHOST: process.env.REACT_APP_ALLOW_LOCALHOST === '1',
  DISABLE_SIGNUP: process.env.REACT_APP_DISABLE_SIGNUP === '1',
  IS_LOCAL: process.env.REACT_APP_IS_LOCAL === '1',
  LCBO_ALLOW_LOCAL: process.env.REACT_APP_LCBO_ALLOW_LOCAL === '1',
  NO_CART: process.env.REACT_APP_NO_CART === '1',
  REDUX_DEV_TOOLS: process.env.REACT_APP_REDUX_DEV_TOOLS === '1',
  USE_STRIPE_PROD: process.env.REACT_APP_USE_STRIPE_PROD === '1',
}
