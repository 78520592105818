import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  delivery: {
    available: ActionType,
    daysClosed: ActionType,
    addDaysClosed: ActionType,
    removeDaysClosed: ActionType,
    daysOpen: ActionType,
    daysOpenEdit: ActionType,
    noContent: ActionType,
    pricing: ActionType,
    schedule: ActionType,
    scheduleNextMonth: ActionType,
    timeslots: ActionType,
  }
})
const _actions = actions.delivery
const deliveryValues = Object.values(_actions)
export default _actions
export { deliveryValues }
