import stages from '../config/stages'
import { progress } from '../actions'

// progress reducer stages helper
export default {
  init: (action, dispatch) => {
    dispatch({
      type: progress[action],
      stage: stages.init,
    })
  },
  initId: (action, dispatch, id) => {
    dispatch({
      type: progress[action],
      stage: stages.initId,
      id,
    })
  },
  err: (action, dispatch, error) => {
    // TODO REMOVE ALL console.log(err) in the code...
    console.log(error)
    dispatch({
      type: progress[action],
      stage: stages.err,
      error,
    })
  },
  errId: (action, dispatch, error, id) => {
    dispatch({
      type: progress[action],
      stage: stages.errId,
      error,
      id,
    })
  },
  fail: (action, dispatch, msg, resp) => {
    console.log(resp)
    dispatch({
      type: progress[action],
      stage: stages.fail,
      msg,
    })
  },
  ok: (action, dispatch, resp) => {
    dispatch({
      type: progress[action],
      stage: stages.ok,
      status: resp.status,
    })
  },
  okId: (action, dispatch, resp, id) => {
    dispatch({
      type: progress[action],
      stage: stages.okId,
      status: resp.status,
      id,
    })
  },
}
