import init from './init/schedule'
import actions from './actions/schedule'

export default (state=init, action) => {
  switch (action.type) {
    case actions.editState: {
      const { dayOfMonth } = action
      return {
        ...state,
        schedule: {
          ...state.schedule,
          editState: {
            ...state.schedule.editState,
            [dayOfMonth]: action.value,
          }
        }
      }
    }
    case actions.clear: {
      const { dayOfMonth } = action
      return {
        ...state,
        schedule: {
          ...state.schedule,
          sched: {
            ...state.schedule.sched,
            [dayOfMonth]: new Set(),
          },
        }
      }
    }
    case actions.init: {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          loaded: true,
          sched: action.sched,
          editState: action.editState,
        }
      }
    }
    case actions.loaded: {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          loaded: action.value,
        }
      }
    }
    case actions.schedule: {
      return {
        ...state,
        schedule: action.value
      }
    }
    case actions.selectSlot: {
      const { dayOfMonth, checked, timeslot } = action
      let day = new Set(state.schedule.sched[dayOfMonth])
      if (checked) {
        day.add(timeslot)
      } else {
        day.delete(timeslot)
      }
      return {
        ...state,
        schedule: {
          ...state.schedule,
          editState: {
            ...state.schedule.editState,
            [dayOfMonth]: true,
          },
          sched: {
            ...state.schedule.sched,
            [dayOfMonth]: Array.from(day),
          }
        }
      }
    }
    case actions.selectAll: {
      const { dayOfMonth, checked, timeslots } = action
      let day = new Set()
      if (checked) {
        timeslots.forEach( (ts, i) => {
          day.add(i + 1)
        })
      }
      return {
        ...state,
        schedule: {
          ...state.schedule,
          editState: {
            ...state.schedule.editState,
            [dayOfMonth]: true,
          },
          sched: {
            ...state.schedule.sched,
            [dayOfMonth]: Array.from(day),
          }
        }
      }
    }
    default:
      return state
  }
}
