import { progressActionValues } from './actions/progress'
import init from './init/progress'
import stages from './config/stages'
import { StatusCodes as http } from 'http-status-codes'

// keep timestamp for future use: auto refresh data
export default (state=init, action) => {
   let a = undefined
   const actionFound = progressActionValues.includes(action.type)
   if (actionFound) a = action.type.replace('progress.', '')
   const errStage = action.stage === stages.err
   const errIdStage = action.stage === stages.errId
   const initStage = action.stage === stages.init
   const initIdStage = action.stage === stages.initId
   const failStage = action.stage === stages.fail
   const okStage = action.stage === stages.ok
   const okIdStage = action.stage === stages.okId

   if (!action.stage && actionFound) {
    return {
      ...state,
      progress: {
        ...state.progress,
        [a]: {
          ...state.progress[a],
          ...action.payload,
          timestamp: Date.now(),
        }
      }
    }
  } else if (initStage && actionFound) {
    return {
      ...state,
      progress: {
        ...state.progress,
        [a]: {
          errorMsg: undefined,
          inProgress: true,
          status: undefined,
          timestamp: Date.now(),
        }
      }
    }
  } else if (initIdStage && actionFound) {
    return {
      ...state,
      progress: {
        ...state.progress,
        [a]: {
          errorMsg: undefined,
          inProgress: true,
          status: undefined,
          timestamp: Date.now(),
          ids: [
            ...state.progress[a].ids,
            action.id,
          ]
        }
      }
    }
  } else if (errStage && actionFound) {
    const { error } = action
    // todo: if there is no response its UNAUTHORIZED
    let status = http.UNAUTHORIZED
    let statusText = `CD Client Side Error: ${error}`
    if (error.response) {
      ;({ status, statusText } = error.response)
    }
    return {
      ...state,
      progress: {
        ...state.progress,
        [a]: {
          errorMsg: statusText,
          status,
          inProgress: false,
          timestamp: Date.now(),
        }
      }
    }
  } else if (errIdStage && actionFound) {
    const { error, id } = action
    // todo: if there is no response its UNAUTHORIZED
    let status = http.UNAUTHORIZED
    let statusText = `CD Client Side Error: ${error}`
    if (error.response) {
      ;({ status, statusText } = error.response)
    }
    return {
      ...state,
      progress: {
        ...state.progress,
        [a]: {
          errorMsg: statusText,
          status,
          inProgress: false,
          timestamp: Date.now(),
          ids: state.progress[a].ids.filter(i => i !== id)
        }
      }
    }
  } else if (failStage && actionFound) {
    return {
      ...state,
      progress: {
        ...state.progress,
        [a]: {
          errorMsg: action.msg,
          status: action.status || http.ok,
          inProgress: false,
          ok: true,
          timestamp: Date.now(),
        }
      }
    }
  } else if (okStage && actionFound) {
    return {
      ...state,
      progress: {
        ...state.progress,
        [a]: {
          errorMsg: undefined,
          status: action.status || http.ok,
          inProgress: false,
          ok: true,
          timestamp: Date.now(),
        }
      }
    }
  } else if (okIdStage && actionFound) {
    return {
      ...state,
      progress: {
        ...state.progress,
        [a]: {
          errorMsg: undefined,
          status: action.status || http.ok,
          inProgress: false,
          ok: true,
          timestamp: Date.now(),
          ids: state.progress[a].ids.filter(i => i !== action.id)
        }
      }
    }
  } else {
    if (action.type.startsWith('progress')) {
      console.warn('progress reducer default')
    }
    return state
  }
}
