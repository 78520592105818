
import axios from 'axios'
import { StatusCodes as http }  from 'http-status-codes'
import { Urls } from '../../config'
import { LinkTo, Tart } from '../../utility'
import getSession from '../util/getsession'

const baseURL = Urls._R

const axUser = axios.create({baseURL})

const handle = error => {
  // ensure error handlers down the line know there is no error.response
  if (!error.response) {
    Tart.warn(error)
    return Promise.reject(error)
  }
  const { status } = error.response
  if (status === http.GATEWAY_TIMEOUT) {
    Tart.warn('Token expired, Please reauthenticate')
    LinkTo.routeLong('/login')
    return Promise.reject(error)
  } else if (status === http.CONFLICT) {
    Tart.warn('CONFLICT: Data is alreay present')
  } else {
    const { method, url } = error.config
    const { status, statusText } = error.response
    const msg = `${status} [${method}]: ${statusText} ${url}`
    Tart.err(msg)
  }
  return Promise.reject(error)
}

axUser.interceptors.request.use(config => {
  if (config.url === config.baseURL) {
    console.warn('root request:')
    console.log(config)
  }
  if (!config.url) {
    throw Error('BAD ROUTE')
  }
	const session = getSession()
	// TODO check token
  config.data = {
    ...config.data,
    session: {
      email: session.email,
      emailCode: session.emailCode,
      firstName: session.firstName,
			isAdmin: session.isAdmin,
			isDriver: session.isDriver,
      phone: session.phone,
      phoneCode: session.phoneCode,
      phoneField: session.phoneField,
			token: session.token,
			userId: session.userId,
		},
  }
  return config
})
axUser.interceptors.response.use((response) => {
  return response
}, handle);

export { axUser }
