import * as A from '../actions'
import Stage from '../util/stage'
import { Express } from '../../config'
import { axUser } from '../config/ax'
import to from 'await-to-js'
import { LinkTo, Tart } from '../../utility'
import getOrders from './getorders'

export default async function stripeCharge(
  dispatch,
  stripeClientData,
  invoice,
  address,
  cart,
  ) {
  const action = 'stripeCharge'
  Stage.init(action, dispatch)
  const [err, resp] = await to(
    axUser.put(Express.checkout, {
      address,
      cart,
      invoice,
      stripeClientData,
    })
  )
  if (err) {
    console.log(err)
    Tart.err('Error confirming your order')
    Stage.err(action, dispatch, err)
    return
  }

  Tart.ok('Order confirmed. Please check your email')
  Stage.ok(action, dispatch, resp)
  dispatch({ type: A.cart.clear })
  getOrders(dispatch)

  // todo purge LinkTo
  // use callback and history push
  // first checkout must become a functional component
  LinkTo.routeSoon('orders')
}