import { constants } from '../config'
import phoneFormatter from 'phone-formatter'

const { TEL_LEN, TEL_TEMPLATE } = constants

// may not be required if using react-text-mask
export default function phoneFormat(s) {
  if (!s) return s
  if (s.length < TEL_LEN) {
    return s.padEnd(TEL_LEN, '.')
  }
  return phoneFormatter.format(String(s), TEL_TEMPLATE)
}