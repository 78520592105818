import { axUser } from '../config/ax'
import { Tart } from '../../utility'
import * as A from '../actions'
import Stage from '../util/stage'
import { Express } from '../../config'
import to from 'await-to-js'

// use to refresh ver status
// user will be clicking on another device
export default async function authTelVerify(dispatch) {
  const action = 'telVerify'
  Stage.init(action, dispatch)
  const url = Express.telVerify
  const [err, resp] = await to(axUser.post(url))
  if (err) {
    console.log(err)
    Stage.err(action, dispatch, err)
    Tart.warn('Error checking code, please refresh page')
    return
  }
  Tart.ok('Mobile Verified!')
  Stage.ok(action, dispatch, resp)
  dispatch({
    type: A.session.telVerify,
  })
}
