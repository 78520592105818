import { axUser } from '../config/ax'
// import Tart from '../../utility/tart'
import { Express } from '../../config'
import Stage from '../util/stage'
import * as A from '../actions'
import to from 'await-to-js'

export default async(dispatch) => {
  const action = 'getOrders'
  Stage.init(action, dispatch)
  const [err, resp] = await to(
    axUser.post(Express.orders)
  )
  if (err) {
    console.log(err)
    // Tart.warn('Error loading carts')
    Stage.err(action, dispatch, err)
    return
  }
  const { carts } = resp.data
  dispatch({
    type: A.carts.set,
    value: carts,
  })
  Stage.ok(action, dispatch, resp)
}
