import { ActionType, createActionTypes } from 'action-types'

// replaces both cart and order reducers.
// do calculations on the fly (use shared npm module)
const actions = createActionTypes({
  simplecart: {
    clear: ActionType,
    clearLcbo: ActionType,
    clearCraft: ActionType,
    isCc: ActionType,
    removeCraftStop: ActionType,
    
    lcboSetQty: ActionType,
    rmLcboItem: ActionType,

    addCraft: ActionType,
    removeCraft: ActionType,
    rmCraftItem: ActionType,
    setToken: ActionType,
    setTimeslot: ActionType,
    refresh: ActionType,
    properIdSeen: ActionType,
  }
})

const simpleCartActions = actions.simplecart
export default simpleCartActions
