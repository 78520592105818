import React from 'react'
import BaseIcon from './baseicon'

export default function BoolIcon(props) {
  if (props.hide) return null

  const {
    falseCol,
    falseIcon,
    trueCol,
    value,
  } = props

  const _falseCol = falseCol ? falseCol : 'red'
  const _falseIcon = falseIcon ? falseIcon : 'close'
  const _trueCol = trueCol ? trueCol : 'green'
 
  return (
    <BaseIcon
      {...props}
      icon={value ? 'agree': _falseIcon }
      color={value ? _trueCol : _falseCol }
    />
  )
}