import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { Beer,  ChevronLeft } from '../icons'

const style = makeStyles({
  root: {
    color: 'lightgrey',
    marginTop: '5px',
    marginBottom: '5px',
  }
})

export default props => 
  <Link className='noDecLink' to={`/shop/${props.slug}`}>
    <Button
      variant='outlined'
      className={style().root}
    >
      <ChevronLeft L />
      {props.shopName}
      <Beer L left={2} />
    </Button>
  </Link>