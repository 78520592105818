import Stage from '../util/stage'
import * as A from '../actions'
import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import { StatusCodes as http }  from 'http-status-codes'
import { Tart } from '../../utility'
import LogRocket from 'logrocket'
import { env } from '../../config'
import { contact } from '../../@craft-delivery/cfg/dist/policies'

export default async function signupConfirmEmail(dispatch, emailhash, callback) {
  const action = 'signupConfirmEmail'
  Stage.init(action, dispatch)
  const url = `${Express.signup}/email/${emailhash}`
  
  const contactUs = `. Contact us at ${contact.email}`
  // TODO return email as text
  const [err, resp] = await to (axios.get(url))

  // we are no longer sending all of these statuses
  // todo: trim
  if (err) {
    Stage.err(action, dispatch, err)
    const { status } = err.response
    let message
    if (status === http.FAILED_DEPENDENCY) {
      message = 'Invalid Email'
    } else if (status === http.EXPECTATION_FAILED) {
      message = 'Invalid Code'
    } else if (status === http.FORBIDDEN) {
      message = `Problem Verifying your email${contactUs}`
    } else if (status === http.NOT_FOUND) {
      message = `We could not find your registration information${contactUs}`
    } else if (status === http.INTERNAL_SERVER_ERROR) {
      message = `Something went wrong on our end${contactUs}`
    } else if (status === http.NO_CONTENT) {
      message = 'Email Already Verified'
    } else {
      message = 'Error Validating Email'
    }
    Tart.warn(message)
    return
  }
  dispatch({
    type: A.session.setEmailIsVerified,
    value: true,
  })
  const { email, ip } = resp.data
  if (!env.IS_LOCAL) {
    LogRocket.identify(email, { ip })
  }
  
  Tart.ok('Thanks for confirming your email!')
  Stage.ok(action, dispatch, resp)
  callback(resp.data)
}