import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import { Tart } from '../../utility'
import { StatusCodes as http } from 'http-status-codes'

export default async(emailhash, cbok, cbconverted, cberr) => {
  const url = Express.comingSoon
  const [err, resp] = await to(
    axios({
      data: { emailhash },
      method: 'LINK',
      url,
    })
  )
  if (err) {
    console.log(err)
    Tart.warn('Error Fetching Your Info')
    const stat = err?.response?.status
    if (stat === http.FORBIDDEN) {
      cberr(`Your registration was paused because you unsubscribed`)
    } else if (stat === http.METHOD_NOT_ALLOWED) {
      cberr(`We couldn't find your invite`)
    } else if (stat === http.NETWORK_AUTHENTICATION_REQUIRED) {
      cberr('Your email has not been confirmed')
    } else {
      cberr('There has been an error fetching your pre-reg info')
    }
    return
  }
  const { data, status } = resp
  if (status === http.NOT_MODIFIED) {
    Tart.info('You are already registered')
    cbconverted(true)
  } else {
    Tart.ok('Welcome Back!')
    cbok(data)
  }
}
