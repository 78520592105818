// new signup flow all in one
import Stage from '../util/stage'
import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import { Tart } from '../../utility'
import signin from './signin'
import { StatusCodes as http } from 'http-status-codes'

export default async(dispatch, payload, callback) => {
  const action = 'signupUser'
  Stage.init(action, dispatch)

  const url = `${Express.signup}/new`
  const [err, resp] = await to(
    axios.put(url, payload)
  )
  if (err) {
    console.log(err)
    const status = err?.response?.status
    if (status === http.CONFLICT) {
      Tart.warn('The phone number you registered with is already in use')
    } else {
      Tart.warn('We were unable to sign you up. A note has been sent to our team.')
    }
    Stage.err(action, dispatch, err)
    return
  }
  Stage.ok(action, dispatch, resp)
  Tart.ok('Signing you in...')
  signin(dispatch, {
    email: payload.email,
    pwd: payload.pwd,
  },
  callback)
}
