import MobileDetect from 'mobile-detect'
import * as A from '../reducer/actions'
import store from '../index'
import screen from './screen'
import { constants } from '../config'

const {
  LARGE_SCREEN,
  SMALL_SCREEN,
} = constants

export default class MobileUtils {
  constructor() {
    this.init()
  }
  init = () => {
    const md = new MobileDetect(window.navigator.userAgent)
    this.md = md
  }
  updateDimensions = () => {
    store.dispatch({
      type: A.main.device,
      value: this.wholeShebang()
    })
  }
  // return an object identical to the reducer structure
  wholeShebang = () => {
    this.init()
    const { h, w } = screen()
    const mobile = {
      userAgentFull: window.navigator.userAgent,
      // userAgent: this.md.userAgent(), this returns null
      os: this.md.os(),
      isBot: this.md.is('bot'),
      small: this.small(),
      smallWidth: this.smallWidth(),
      largeWidth: this.largeWidth(),
      isMobile: this.isMobile(),
      mobileDetectIsMobile: this.md.mobile(),
      iOS: this.iOS(),
      iPhone: this.iPhone(),
      isSafari: /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent),
      isFirefox: /firefox/i.test(navigator.userAgent),
      isPortrait: this.isPortrait(),
      phone: this.md.phone(),
      tablet: this.md.tablet(),
      h,
      w,
      pad: w < SMALL_SCREEN ? 2 : 5,
      padr: w < SMALL_SCREEN ? 0 : 0.4,
      maxWidth: this.md.maxPhoneWidth
    }
    // console.log(mobile)
    return mobile
  }

  isMobile = () => this.md.phone() || this.md.tablet()

  iOS = () => this.md.os() === 'iOS'

  iPhone = () => this.md.is('iPhone')

  isPortrait = () => screen().h > screen().w ? true : false
  // a more important case
  smallWidth = () => screen().w < SMALL_SCREEN

  largeWidth = () => screen().w > LARGE_SCREEN

  small = () => {
    if (this.isPortrait()) {
      return screen().w < SMALL_SCREEN
    }
    else {
      return screen().w < SMALL_SCREEN
    }
  }
}
