import {
  imgUrls,
  timeouts,
  // actions as A,
} from '../index'
import activeShopsRegional from './activeshopsregional'
import {
  // getItem,
  // storageKeys as keys,
  tabCounter,
} from '../../utility'
import _initClientLambda from './_initclientlambda'
import cTables from './ctables'
import lookups from './lookups'
import pricing from './pricing'

// should we do a purge on local storage if outdated???
export default (dispatch /* , cb*/) => {
  tabCounter.inc()
  cTables(dispatch)
  lookups(dispatch)
  pricing(dispatch)
  // /carts
  // if cart is empty and hash = '#/cart' and localStorage cart exists,
  // then display a refresh cart button

  
  // const cart = getItem(keys.cart)
  // if (cart) {
  //   const jcart = JSON.parse(cart)
  //   const hash = window.location.hash
  //   if (hash !== '#/cart') {
  //     dispatch({ type: A.cart.refresh, cart: jcart })
  //     localStorage.removeItem(keys.cart)
  //   }
  // }


  // const { enableSocketRefresh } = A.session
  // dispatch({ type: A.auth.refresh })
  // dispatch({ type: enableSocketRefresh })

  imgUrls(dispatch)
  timeouts(dispatch)
  _initClientLambda(dispatch) 
  activeShopsRegional(dispatch)
  ///////////////////
}