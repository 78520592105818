import React from 'react'
import { Button } from '@material-ui/core'
import {
  CreditCard,
  Email,
} from '../icons'

const RIGHT = 2

// so this component should check for address
// Complete Reg should redirect to the new auth flow
// Verify Mobile
// this has too many side effects
export default function RegComplete(props) {
  const {
    ccPay,
    complete,
    hasAddress,
    success,
    showProgress,
  } = props
  let text = null
  let handle = complete
  
  // Login will be covered in the Cart,
  // These are checkout buttons
  //
  if (!hasAddress) {
    text = 'Complete Address'
  } else {
    if (ccPay) {
      text = (
        <span className='no-caps'>
          <CreditCard L color='green' right={RIGHT} />
          Buy Now
        </span>
      )
    } else {
      text = (
        <span className='no-caps'>
          <Email L color='green' right={RIGHT} />
          Buy Now
        </span>
      )
    }
    handle = success
  }
  return <Button
    variant='outlined'
    className='checkout-button'
    onClick={handle}
    color='default'
    disabled={showProgress}
  >
    {text}
  </Button>
}