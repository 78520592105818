Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOpenRange = exports.getHourString = exports.fixHours = exports.openingColumn = exports.lastCall = exports.closingColumn = undefined;

var _config = require('../config');

var lastCall = 100;

// times are stored as ints
var getHourString = function getHourString(militaryFormat) {
  var last2 = void 0,
      newIntTime = void 0;
  var formattedTime = '';
  var firstPart = '';

  if (militaryFormat === 0) {
    formattedTime = 'closed';
  } else if (militaryFormat === 2400) {
    formattedTime = '12:00am';
  } else if (militaryFormat >= 1200) {
    newIntTime = (militaryFormat - 1200).toString();
    last2 = newIntTime.slice(-2);
    if (last2 === '0') {
      last2 = '00';
    }
    if (newIntTime.length < 4) {
      firstPart = newIntTime[0];
    } else {
      firstPart = newIntTime.slice(0, 2);
    }
    if (firstPart === '0') {
      firstPart = '12';
    }
    formattedTime = firstPart + ':' + last2 + 'pm';
  } else {
    newIntTime = militaryFormat.toString();

    last2 = newIntTime.slice(-2);
    if (last2 === '0') {
      last2 = '00';
    }
    if (newIntTime.length < 4) {
      firstPart = newIntTime[0];
    } else {
      firstPart = newIntTime.slice(0, 2);
    }
    if (firstPart === '0') {
      firstPart = '12';
    }
    formattedTime = firstPart + ':' + last2 + 'am';
  }
  return formattedTime;
};

var getOpenRange = function getOpenRange(openTime, closeTime) {
  var closeString = void 0,
      openString = void 0;

  if (openTime === 0 && closeTime === 0) {
    return 'closed';
  } else {
    openString = getHourString(openTime);
    closeString = getHourString(closeTime);
    return openString + ' - ' + closeString;
  }
};

var fixHours = function fixHours(h) {
  if (h === '' || h === undefined || h === null) {
    return 0;
  } else {
    return parseInt(h);
  }
};

var closingColumn = function closingColumn() {
  var dayIndex = new Date().getDay();
  return _config.daysClose[dayIndex];
};

var openingColumn = function openingColumn() {
  var dayIndex = new Date().getDay();
  return _config.daysOpen[dayIndex];
};

exports.closingColumn = closingColumn;
exports.lastCall = lastCall;
exports.openingColumn = openingColumn;
exports.fixHours = fixHours;
exports.getHourString = getHourString;
exports.getOpenRange = getOpenRange;