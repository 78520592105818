import { Lambda } from '../../config'
import axios from 'axios'
import { actions as A } from '../index'
import to from 'await-to-js'

export default async(dispatch) => {
  const [err, resp] = await to(
    axios.get(Lambda.lookups)
  )

  if (err) {
    console.log(err)
  } else {
    const { lookups } = resp.data
    dispatch({
      type: A.lookups.init,
      data: lookups,
    })
    const [err2, resp2] = await to(
      axios.get(Lambda.lookups2)
    )
    if (err2) {
      console.log(err2)
      return
    }
    dispatch({
      type: A.lookups.lookups2,
      payload: resp2.data,
    })
    dispatch({
      type: A.lcbobeta.cat2ByCat,
      cat2Ids: resp2.data.cat2Ids,
    })
  }
  

}