export let makeCraftItem = (item, inv, qty=1) => {
  return {
    unitId: item.id,
    isLcbo: false,
    name: item.name,
    abv: inv.abv,
    pkgid: item.pkgid,
    priceCents: item.price,
    shopId: item.shop_id,
    bottleId: item.bottle_id,
    qty: qty
  }
}
