import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { Cart } from '../icons'

const style = makeStyles({
  root: {
    color: 'lightgrey',
    marginTop: '5px',
    marginBottom: '5px',
  }
})

export default props => 
  <Link className='no-dec-link' to='/cart'>
    <Button
      style={props.style}
      variant='outlined'
      className={style().root}
    >
      <Cart L />
      Cart
    </Button>
  </Link>