export default {
  regions: {
    subdomain: null,
    areas: null,

    // TODO: debug init
    current: {
      id: 1,
      region_short: 'Toronto',
      subregion: 'Toronto',
      servicearea: 'Toronto West of Victoria Park Ave',
      active: true,
      servingfromid: 1,
      keepcart: [24],
    },
    defaultId: 1,
    list: [],
    byId: {},
    byRegion: {},

    /*
    invFresh keeps track of the last update for a given region
    NOTE: being done in regions becuase the regions state is already
          passed to getInventories
    if < x hours do not refresh
    invFresh[regionId]: timestamp
    */
    invFresh: {},
  }
}
