
// TODO: do we really need status and timestamp here?
const init = {
  errorMsg: undefined,
  inProgress: false,
  ok: false,
  status: undefined,
  timestamp: undefined,
}

// important to initialize because:
// we may be checking nested properties:
// `progress.bottleAdd.inProgress` etc...
// A lot of admin bloat we don't necessarily need here
//
// TODO / TBD can we have an admin reducer that is not combined until admin login???
//
export default {
  progress: {
    addressSubmit: init,
    cartSave: init,
    comingSoon: init,
    comingSoonConfirm: init,
    comingSoonUnsub: init,
    daysGet: init,
    dropdowns: init,
    emailCheckout: init,
    // emailResendConf: init,
    getInventory: init,
    getOrders: init,
    lcbo: init,
    orderInfo: init,
    shopSearch: init,
    signin: init,
    signinSession: init,
    signinToken: init,

    // todo: purge what we don't need 
    signupUser: init,
    signupCheck: init,
    signupEmail: init,
    signupPhone: init,
    telVerify: init,
    submitPhone: init,

    signupConfirmEmail: init,
    signupEmailResend: init,
    stripeCharge: init,
    stripeRefund: init,
    unsub: init,
  }
}
