import actions from './actions/history'
import init from './init/history'

const MAX_HISTORY = 50
// todo translate route to user name
// CALLED FROM HistoryHelper using useHistory.listen
// make /l more readable TODO
export default (state=init, action) => {
  switch (action.type) {
    
    // push onto stack, remove if 
    case actions.routes: {
      let newHistory = [
        action.route,
        ...state.history.routes,
      ]
      if (newHistory.length > MAX_HISTORY) {
        newHistory = newHistory.slice(0, MAX_HISTORY - 1)
      }
      
      return {
        ...state,
        history: {
          ...state.history,
          routes: newHistory,
        }
      }
    }
    default: 
      return state
  }
}
