import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  schedule: {
    clear: ActionType,
    editState: ActionType,
    init: ActionType,
    loaded: ActionType,
    sched: ActionType,
    selectAll: ActionType,
    selectSlot: ActionType,
  }
})
const _actions = actions.schedule
export default _actions
