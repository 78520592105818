import axios from 'axios'
import to from 'await-to-js'
import { Express } from '../../config'
import { actions } from '../index'

// call on init as well as region change
export default async function lPreload(dispatch, rid) {
  const url = `${Express.lPreload}/${rid}`
  const [err, resp] = await to(axios.get(url))

  if (err) {
    console.log(err)
    return
  }
  dispatch({
    type: actions.lcbobeta.preload,
    preload: resp.data,
    rid,
  })

}