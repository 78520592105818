import React from 'react'
import get from 'lodash.get'
import icons from '../config/fa'

export default function Icon(props) {
  let {
    bold,
    color,
    double,
    D,
    faq,
    headerClass,
    icon,
    large,
    L,
    overlay,
    padLeft,
    padRight,
    size,
    small,
    S,
    title,
    triple,
    T,
  } = props
  // 3px = 1 space
  const padPixels = 3
  const cssStyle = {}
  if (large || L) size = 'fa-lg'
  if (double || D) size = 'fa-2x'
  if (small || S) size = 'fa-xs'
  if (triple || T) size = 'fa-3x'

  // use for all cases as we have nested properties in icons
  let iconClass = get(icons, icon)
  if (size) {
    iconClass = `${iconClass} ${size}`
  }
  if (headerClass) {
    iconClass = `${iconClass} ${headerClass}`
  }
  if (faq) {
    const icol = faq ? 'faq-open' : 'faq-close'
    iconClass = `${iconClass} ${icol}`
  } else if (color && !headerClass) {
    cssStyle['color'] = `${color}`
  } else {
    color = 'white'
  }
  if (padRight) {
    cssStyle['marginRight'] = `${padRight * padPixels}px`
  }
  if (padLeft) {
    cssStyle['marginLeft'] = `${padLeft * padPixels}px`
  }
  if (bold) {
    cssStyle['webkitTextStroke'] = `2px ${color}`
  }
  //TODO: GET THIS WORKING PROPERLY
  if (overlay) {
    return (
      <span className="fa-layers fa-fw" >
        <i style={cssStyle} className={iconClass}></i>
        <span className="fa-layers-text fa-invers">
          {overlay}
        </span>
      </span>
    )
  }
  // TODO: https://material-ui.com/demos/tooltips/
  return (
    <span
      title={title}
      onClick={props.onClick}
    >
      <i style={cssStyle} className={iconClass}></i>
    </span>
  )
}