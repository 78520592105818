import store from '../index'
import actions from '../reducer/actions/poptart'
import { v1 as uuid } from 'uuid'

// keep this so we can have a drop in replacement of the Tart static class
// since this is used extensively in the app,
// it would be nice if all calls to Tart were unchanged
// this class becomes a dispatcher instead of a jquery toastr trigger


/*
[
  ...
  snack: {
      message,
      duration,
      variant,
    }
]
*/

// const LOG = false


// default duration: better to keep it a bit long, can always pass in a smaller value
const DURATION = 2900

const make = (message, variant, duration=DURATION) => {
  const id = uuid()
  return {
    id,
    message,
    variant,
    duration,
  }
}
const makeIcon = (message, variant, icon) => {
  const id = uuid()
  return {
    id,
    message,
    variant,
    duration: DURATION,
    icon,
  }
}

// TODO play around with positioning
export default {
  okIcon: (message, icon) => {
    store.dispatch({
      type: actions.add,
      snack: makeIcon(message, 'ok', icon)
    })
  },
  ok: (message, duration=DURATION) => {
    store.dispatch({
      type: actions.add,
      snack: make(message, 'ok', duration),
    })
  },
  info: (message, duration=DURATION) => {
    store.dispatch({
      type: actions.add,
      snack: make(message, 'info', duration),
    })
  },
  err: (message, duration=DURATION) => {
    store.dispatch({
      type: actions.add,
      snack: make(message, 'err', duration),
    })
  },
  warn: (message, duration) => {
    store.dispatch({
      type: actions.add,
      snack: make(message, 'warn', duration),
    })
  },
}