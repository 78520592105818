import { axUser } from '../config/ax'
import to from 'await-to-js'
import {
  actions as A,
} from '../index'
import { Express } from '../../config'
import Stage from '../util/stage'
import { StatusCodes as http } from 'http-status-codes'

// kind of want to replace session with email but not sure
// if any other callers send session here
// List callers here:
//  - portals/order
export default async(dispatch, session, ott, cid) => {
  const action = 'orderInfo'
  Stage.init(action, dispatch)
  const url = Express.orderinfo
    const [e, resp] = await to(
      axUser.post(url, { ott, cid })
    )
    if (e) {
      let msg
      const { orderInfoMsg } = A.carts
      if (e.response) {
        const { status } = e.response
        if (status) {
          if (status === http.GONE) {
            msg = `This order (#${cid}) has been refunded`
          } else if (status === http.CONFLICT) {
            msg = 'You have initated a dispute on this order'
          } else if (status === http.NOT_FOUND) {
            msg = 'We could not find this order'
          } else if (status === http.EXPECTATION_FAILED) {
            msg = 'The status of your order cannot be determined at this time'
          } else {
            msg = `order status: ${status}`
          }
        } else {
          msg = 'There was an error getting this order'
        }
      } else {
        console.log(e)
        msg = 'There was an error getting this order'
      }
      dispatch({ type: orderInfoMsg, msg })
      Stage.err(action, dispatch, e)
      return
    }
    const {
      address,
      emailConf,
      invoice,
      chargeType,
      orderType,
      refundType,
      refundAmount,
    } = resp.data
    let driverEmail, clientEmail
    if (!emailConf) {
      console.log('no email conf')
      return
    }
    if (emailConf) {
      emailConf.forEach(conf => {
        // we can have multiple destinations, especially with cc/bcc etc
        if (conf.destination.includes(session.email)) {
          clientEmail = conf.notification_type
        } else {
          driverEmail = conf.notification_type
        }
      })
    }
    const { orderInfo } = A.carts
    const order = {
      driverEmail,
      clientEmail,
      invoice,
      address,
      chargeType,
      orderType,
      refundType,
      refundAmount,
    }
    dispatch({ type: orderInfo, order })
    Stage.ok(action, dispatch, resp)
}