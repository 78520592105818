Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InvoiceDetails = exports.DepositDetails = undefined;

var _deposit = require('./deposit');

var _deposit2 = _interopRequireDefault(_deposit);

var _invoice = require('./invoice');

var _invoice2 = _interopRequireDefault(_invoice);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.DepositDetails = _deposit2.default;
exports.InvoiceDetails = _invoice2.default;