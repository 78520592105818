export default {
  auth: {
    // id: undefined,
    // available: null,// todo rename emailAvailable
    // email: undefined,
    // emailConf: undefined,
    // emailConfMsg: undefined,
    // emailVer: false,
    emailsAvailable: [],
    emailsUnavailable: [],
    // firstName: '',
    // lastName: '',
    // numbersAvailable: [],
    // numbersUnavailable: [],
    // phone: undefined,
    // phoneAvailable: false,
    // phoneConf: undefined,
    // phoneVer: false,
    // postalCode: '',
    // // from /signup/:convcode
    // conversionUser: null,
    // convcode: null,
    inviteEmail: null,
    inviteSubmitCallback: null,
    submitCallback: null,
  }
}
