import { ActionType, createActionTypes } from 'action-types'

// TODO/TBD should this be orders????
const actions = createActionTypes({
  carts: {
    set: ActionType,
    cartSave: ActionType,
    clear: ActionType,
    summary: ActionType,
    clearSummary: ActionType,
    orderInfo: ActionType,
    orderInfoMsg: ActionType,
  }
})
const _actions = actions.carts
export default _actions
