// shared data between lcbo and craft - post normalization
export default {
  lookups: {
    cat: null,
    catCraft: null,
    cat2: null,
    package: null,
    origin: null,
    producer: {},
    style: null,
    sweet: null,
    varietal: {},
    varietals: {},
    cTables: null,
  }
}