import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  bottleshop: {
    shops: ActionType,
    setAll: ActionType,
    clearAll: ActionType,
    // reset: ActionType,
    setUnits: ActionType,
    initNewShopUnits: ActionType,
    initNewBottleUnits: ActionType,
    inventories: ActionType,
    preload: ActionType,
    shopUrls: ActionType,
    showBuyDirectInfo: ActionType,
  }
})
const _actions = actions.bottleshop
export default _actions
