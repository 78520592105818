import {
  actions as A,
  getOrders,
 } from '../index'

 
// final step in signin process
// call after Stage.ok

export default async (dispatch, data, cb=null) => {
  const { addresses, addressPrimaryId } = data
  const { loadData } = A.address
  const {
    // enableSocket,
    loginUser,
  } = A.session

  dispatch({ type: loadData, addressPrimaryId, addresses})
  const userSession = dispatch({
    type: loginUser,
    data,
    hasAddress: !!addressPrimaryId,
  })
  getOrders(dispatch)
  // dispatch({ type: enableSocket })

  if (cb) cb(userSession.data.user)
}