import React from 'react'
import BaseIcon from './baseicon'

export default function CloseIcon(props) {
  return props.hide ?
    null :
    <BaseIcon
      {...props}
      icon='close'
    />
}