import init from './init/img'
import actions from './actions/img'

export default (state=init, action) => {
  switch (action.type) {
    case actions.beers: {
      return {
        ...state,
        img: {
          ...state.img,
          beers: action.beers,
        }
      }
    }
    case actions.urlRoot: {
      return {
        ...state,
        img: {
          ...state.img,
          urlRoot: action.urlRoot,
        }
      }
    }
    default: {
      if (action.type.startsWith('img')) {
        console.warn('img reducer default')
      }
      return state
    }
  }
}
