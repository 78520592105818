import init from './init/auth'
import actions from './actions/auth'

/*

1) ignore / disable socket updates
2) no refresh
3) get flow working by
  - closing the window after confs sent
  - picking up in the link
  - don't verify phone right away
  - some people already have email /pc done!
  - allow phone opt out...
*/

export default (state=init, action) => {
  switch (action.type) {
    case actions.inviteEmail: {
      return {
        ...state,
        auth: {
          ...state.auth,
          inviteEmail: action.value,
        }
      }
    }
    case actions.inviteSubmitCallback: {
      return {
        ...state,
        auth: {
          ...state.auth,
          inviteSubmitCallback: action.value,
        }
      }
    }
    case actions.submitCallback: {
      return {
        ...state,
        auth: {
          ...state.auth,
          submitCallback: action.value,
        }
      }
    }
    // todo rename emailAvailable
    case actions.available: {
      const { email } = action
      const uniq = [...state.auth.emailsAvailable, email]
        .filter((v, i, self) => self.indexOf(v) === i)
      return {
        ...state,
        auth: {
          ...state.auth,
          available: true,
          emailsAvailable: uniq,
          email,
        }
      }
    }
    // use to prepopulate signup form
    // TODO rework this Oct 19 2020
    // case actions.authConvUser: {
    //   const { conversionUser } = action
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       conversionUser,
    //     }
    //   }
    // }
    // case actions.clear.email: {
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       email: undefined,
    //       emailConf: undefined,
    //       emalVer: false,
    //     }
    //   }
    // }

    // from socket server
    // there is no such action!!!!
    // case actions.verifyEmail: {
    //   const {
    //     email,
    //     emailver: emailVer,
    //     emailconf: emailConf,
    //     phone,
    //     phonecode: phoneCode,
    //     phonever: phoneVer,
    //     stage,
    //   } = action.payload
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       email,
    //       emailConf,
    //       emailVer,
    //       phone,
    //       phoneCode,
    //       phoneVer,
    //       stage,
    //     }
    //   }
    // }
    // case actions.clear.phone: {
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       phone: undefined,
    //       phoneConf: undefined,
    //       phoneVer: false,
    //     }
    //   }
    // }
    // case actions.clear.all: {
    //   return init
    // }
    // case actions.email: {
    //   const { email } = action
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       email,
    //     }
    //   }
    // }
    // // STAGE 1
    // case actions.emailConf: {
    //   const { email, emailConf, stage } = action
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       email,
    //       emailConf,
    //       stage,
    //     }
    //   }
    // }
    // case actions.emailNotVer: {
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       emailVer: false,
    //       emailConf: undefined
    //     }
    //   }
    // }
    // case actions.emailVer: {
    //   const {
    //     email,
    //     emailcode: emailConf,
    //     emailver: emailVer,
    //     stage,
    //   } = action.value
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       email,
    //       emailConf,
    //       emailVer,
    //       stage,
    //     }
    //   }
    // }
    // case actions.phone: {
    //   const { phone } = action
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       phone,
    //     }
    //   }
    // }
    // case actions.phoneUnavailable: {
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       phoneAvailable: false,
    //     }
    //   }
    // }
    // case actions.phoneAvailableFail: {
    //   const { phone } = action
    //   const uniq = [...state.auth.numbersUnavailable, phone]
    //     .filter((v, i, self) => self.indexOf(v) === i)
    //   return {
    //   ...state,
    //   auth: {
    //     ...state.auth,
    //     phoneAvailable: false,
    //     numbersUnavailable: uniq,
    //   }
    // }
    // }
    // case actions.phoneAvailable: {
    //   const { phone, payload } = action
    //   const uniq = [...state.auth.numbersAvailable, phone]
    //     .filter((v, i, self) => self.indexOf(v) === i)
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       phoneAvailable: true,
    //       numbersAvailable: uniq,
    //       ...payload,
    //     }
    //   }
    // }

    // case actions.phoneConf: {
    //   const {
    //     phone,
    //     phoneconf: phoneConf,
    //     phonever: phoneVer,
    //     stage,
    //   } = action.payload
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       phone,
    //       phoneConf,
    //       phoneVer,
    //       stage,
    //     }
    //   }
    // }

    // we want to repopulate auth
    // instead of phoneVer. because its a portal click...
    // case actions.phoneVerifyAuth: {
    //   const {
    //     email,
    //     emailcode: emailConf,
    //     emailver: emailVer,
    //     phone,
    //     phonecode: phoneConf,
    //     phonever: phoneVer,
    //     stage,
    //   } = action.auth

    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       email,
    //       emailConf,
    //       emailVer,
    //       phone,
    //       phoneConf,
    //       phoneVer,
    //       stage,
    //     }
    //   }
    // }
    // case actions.phoneVer: {
    //   const { phoneVer } = action
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       phoneVer,
    //     }
    //   }
    // }

    // if we check the email address and its actually 
    // already verified we want to 'resume' the signup
    // process
    // Called from Stage 0
    // set stage to whatever is in the database
    // case actions.resume: {
    //   return {
    //     ...state,
    //     auth: {
    //       ...state.auth,
    //       ...action.data,
    //     }
    //   }
    // }
    case actions.unavailable: {
      const { email } = action
      const uniq = [...state.auth.emailsUnavailable, email]
        .filter((v, i, self) => self.indexOf(v) === i)
      return {
        ...state,
        auth: {
          ...state.auth,
          available: false,
          email,
          emailsUnavailable: uniq,
        }
      }
    }
    default: {
      if (action.type.startsWith('auth')) {
        console.warn('auth reducer default')
      }
      return state
    }
  }
}
