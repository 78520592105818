import Stage from '../util/stage'
import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import { StatusCodes as http } from 'http-status-codes'
import { Tart } from '../../utility'

// signup/phone

// a socket should have been enabled in authemail
// in order to get to this stage
// send in email and emailConf for extra verification

// SEND CONFIRMATION SMS
// auth: email, phone, phoneCode, uid
// TODO: resend? or can we detect from sms table
export default async function authTel(dispatch, auth) {
  const action = 'signupPhone'
  Stage.init(action, dispatch)
  const url = `${Express.signup}/phone`

  const [err, resp] = await to(
    axios.patch(url, { auth })
  )
  if (err) {
    const { status, sysNum } = err.response
    if (status === http.FAILED_DEPENDENCY || status === http.EXPECTATION_FAILED) {
      Tart.err('Error sending verification Text')
    } else if (status === http.PROXY_AUTHENTICATION_REQUIRED) {
      // TODO STICKY TART
      Tart.warn(`Error sending verification Text. It looks like you have unsubscribed to our system number, please text START to ${sysNum}` )
    } else {
      Tart.warn('We were unable to send a verification text message')
    }
    Stage.err(action, dispatch, err)
    return
  }

  Tart.ok('Thanks for verifying your email. We have send a confirmation text to your mobile number in order to verify your phone number')
  Stage.ok(action, dispatch, resp)
}
