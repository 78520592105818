import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  auth: {
  //   clear: {
  //     email: ActionType,
  //     phone: ActionType,
  //     all: ActionType,
  //   },

  //   // trigger loading of user and composite conf code loaded in from /signup/:convcode
  //   authConvUser: ActionType,

  //   refresh: ActionType,
  //   // resend: ActionType,
  //   resume: ActionType,
    available: ActionType,

  //   // user signed up for updates
  //   // and we have some info like a verified email...
    availableComingSoon: ActionType,
    
    email: ActionType,
    emailConf: ActionType,
    emailConfMsg: ActionType,
    emailNotVer: ActionType,
    emailVer: ActionType,
    verifyEmail: ActionType,
  //   phone: ActionType,
  //   phoneAvailable: ActionType,
  //   phoneAvailableFail: ActionType,
  //   phoneUnavailable: ActionType,
  //   phoneConf: ActionType,
  //   phoneVer: ActionType,
  //   phoneVerifyAuth: ActionType,
    unavailable: ActionType,
    inviteEmail: ActionType,
    inviteSubmitCallback: ActionType,
    submitCallback: ActionType,
  }
})
const authActions = actions.auth
export default authActions
