import {
  useEffect,
  useState,
} from 'react'
// import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  getSelector,
  lProds,
  lCatAgg,
} from '../../reducer'


// move stuff out of Component based MAIN which we hope to deprecate
// a renderless component

export default function LcboDataHelper() {
  const dispatch = useDispatch()
  const [rid, setRid] = useState(null)
  const { current } = getSelector('regions')

  useEffect(() => {
    if (current.id) {
      if (current.id !== rid) {
        setRid(current.id)
        lProds(dispatch, current.id)
        lCatAgg(dispatch, current.id)
      }

    }
  }, [
    current.id,
    dispatch,
    rid,
  ])

  return null
}