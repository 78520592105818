export default {
  session: {
    agreetos: false,
    authId: undefined,
    city: '',
    editPhoneModal: false,
    email: null,
    emailCode: null,
    emailVerified: null,
    // enableSocket: false,
    failedLogin: null, // email address/ use in forgot pwd form
    firstName: '',
    hasAddress: false,
    hood: '',
    invalid: false,
    isAdmin: false,
    isDriver: false,
    isLoggedIn: false,
    isRegion: true,
    lastName: '',
    lat: -1,
    lng: -1,
    main: 617,
    nearby: [],
    phone: '',
    pwd: null,
    regionDropDown: 'All Regions',
    phoneCode: null,
    phoneVerified: null,
    phoneField: '',
    postal: '',
    stayLoggedIn: false,
    token: {
      iat: null,
      exp: null,
      t: null,
      valmins: null
    },
    userId: null,
    tokenLoginMsg: null,
    stripe: {
      useStripeProd: false,
      sPublicTest: null,
      sPublicProd: null,
    }
  }
}
