import init from './init/regions'
import actions from './actions/regions'
import { setItem, storage } from '../utility'

export default (state=init, action) => {
  switch (action.type) {
    case actions.subdomain: {
      return {
        ...state,
        regions: {
          ...state.regions,
          subdomain: action.value,
        }
      }
    }
    // we had to spread areas because it was double nested areas.areas
    case actions.areas: {
      return {
        ...state,
        regions: {
          ...state.regions,
          // ...action.areas,
          areas: action.areas,
        }
      }
    }
    // CALL THIS BEFORE THE LOCAL STORAGE REFRESH
    // byId is a quick lookup by region_short (TEMP)
    // byId should be called shortById...
    // although we want a byId instead of byRegion eventually...
    case actions.list: {
      const { defaultId } = state.regions
      const { postalCodes, value } = action
      const byRegion = {}
      const byId = {}
      if (!value) return state
      // TODO by region and byid get from server
      value.forEach(r => {
        byRegion[r.region_short] = r
        byId[r.id] = r.region_short
      })
      return {
        ...state,
        regions: {
          ...state.regions,
          byId,
          byRegion,
          list: value,
          current: value.find(o => o.id === defaultId),
          postalCodes,
        }
      }
    }
    case actions.initRegion: {
      const { region } = action
      const { regions } = state
      return {
        ...state,
        regions: {
          ...regions,
          list: [...regions.list, region],
          byRegion: {
            ...regions.byRegion,
            [region.region_short]: region,
          }
        }
      }
    }
    // region is region_short
    case actions.current: {
      const { region } = action
      const { regions } = state
      const current = regions.list.find(o => o.region_short === region) || state.current
      if (!current) {
        return state
      }
      // put back. Was ths causing issues why was it removed?
      setItem(storage.keys.region, current)
      return {
        ...state,
        regions: {
          ...regions,
          current: current,
        }
      }
    }
    // region is region object
    case actions.setCurrent: {
      const { regions } = state
      const { region } = action
      if (!region) {
        return state
      }
      // put back. Was ths causing issues why was it removed?
      setItem(storage.keys.region, region)
      return {
        ...state,
        regions: {
          ...regions,
          current: region,
        }
      }
    }
    // we are not calling this in init anymore
    case actions.invFresh: {
      const { regions } = state
      const { regionId } = action
      return {
        ...state,
        regions: {
          ...regions,
          invFresh: {
            ...regions.invFresh,
            [regionId]: new Date().getTime()
          }
        }
      }
    }
    default:
      return state
  }
}
