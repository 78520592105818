import actions from './actions/carts'
import init from './init/carts'

export default (state=init, action) => {
  switch (action.type) {
    case actions.orderInfo: {
      return {
        ...state,
        carts: {
          ...state.carts,
          orderInfo: action.order,
        }
      }
    }
    case actions.cartSave: {
      return {
        ...state,
        carts: {
          ...state.carts,
          list: [action.order, ...state.carts.list],
        }
      }
    }
    case actions.orderInfoMsg: {
      return {
        ...state,
        carts: {
          ...state.carts,
          orderInfoMsg: action.msg,
        }
      }
    }
    case actions.summary:
      const { id, invoice, craft, token, date } = action.value
      return {
        ...state,
        carts: {
          ...state.carts,
          summary: {
            token,
            id,
            invoice,
            date,
            craft: {
              ...craft,
              token: null,
            }
          },
        }
      }
    case actions.clearSummary:
      return {
        ...state,
        carts: {
          ...state.carts,
          summary: undefined,
        }
      }
    case actions.clear:
      return {
        ...state,
        carts: {
          ...state.carts,
          list: [],
        }
      }
    case actions.set:
      return {
        ...state,
        carts: {
          ...state.carts,
          list: action.value
        }
      }
      default: {
        if (action.type.startsWith('carts')) {
          console.warn('carts reducer default')
        }
        return state
      }
  }
}
