import React from 'react'
import { useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import {
  IconButton,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core'
import { amber, green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { actions as A, getSelector } from '../reducer'
import Icon from './icon'

/*
  - Tart will push the snack onto the queue in the poptart reducer
  - TODO debug close fire issue
*/

const useStyles = makeStyles(theme => ({
  ok: {
    backgroundColor: green[600],
  },
  err: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warn: {
    backgroundColor: amber[700],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
}))

export default function PopTart(props) {
  const classes = useStyles()
  const d = useDispatch()
  const poptart = getSelector('poptart')
  const { snacks } = poptart

  // autoHideDuration is supposed to wait but its firing immediatley
  // this is not ideal keep an eye on 
  const close = (dur) => {
    const closeDur = dur || 1250
    setTimeout(() => {
      const { id } = snacks[0]
      d({ type: A.poptart.rm, id })
    }, closeDur)
  }

  const onExit = () => {
    const { id } = snacks[0]
    d({ type: A.poptart.rm, id })
  }

  const { ...other } = props
  
  if (!snacks || !snacks.length) {
    return null
  }
  // grab first snack
  // need a default int duration
  const { duration=3000, icon, message, variant } = snacks[0]

  let msg = message
  if (typeof(message) === 'object') {
    msg = JSON.stringify(message)
  }
  return (
    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={true}
        autoHideDuration={duration}
        onClose={ () => close(duration)}
        onExited={onExit}
      >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span
            id="client-snackbar"
            className={classes.message}>
            {
              !icon ? null : <Icon large icon={icon} padRight={2} />
            }
            {msg}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={close}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  )
}