import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import LcboBlogProduct from '../lcbo/blogproduct'

// access
const ForgotForm = lazy(() => import('../auth/access/resetpwd'))
const Login = lazy(() => import('../auth/access/login'))
const Signout = lazy(() => import('../auth/access/signout'))
const ResetClick = lazy(() => import('../auth/access/pwdresetform'))
const SignupHandler = lazy(() => import('../auth/access/signup'))

// comingsoon TEMP
const ComingSoonConfirm = lazy(() => import('../auth/comingsoon/confirm'))
const ComingSoonUnsub = lazy(() => import('../auth/comingsoon/unsub'))
const ComingSoonInvite = lazy(() => import('../auth/comingsoon/invite'))

//info
const ContactUs = lazy(() => import('../info/contactus'))
const HelpFaq = lazy(() => import('../info/helpfaq'))
const HoursContact = lazy(() => import('../info/hourscontact'))
const HoursOfOperation = lazy(() => import('../info/hoursofoperation'))
// should this be in info?
const GetPaid = lazy(() => import('../auth/getpaid'))

// user
const Account = lazy(() => import('../auth/user/accounttabs'))
const Address = lazy(() => import('../auth/user/address'))
const Carts = lazy(() => import('../auth/user/carts'))

// verify
const PhoneVerify = lazy(() => import('../auth/verify/phone')) 
const VerifyClick = lazy(() => import('../auth/verify/email'))

const Bottle = lazy(() => import('../bottle'))
const BottleShops = lazy(() => import('../bottleshops'))
const CancelOrder = lazy(() => import('../portals/cancelorder'))
const CartView = lazy(() => import('../cart/cartview'))
const Checkout = lazy(() => import('../checkout'))

const Landing = lazy(() => import('../landing/index'))
const LcboBeta = lazy(() => import('../lcbo/index'))
const LcboProduct = lazy(() => import('../lcbo/lcboproduct'))
const LProducer = lazy(() => import('../lcbo/lproducer'))
const LcboSale = lazy(() => import('../lcbo/lcbosale'))
const LcboRegPrice = lazy(() => import('../lcbo/lcboregprice'))

const NoPhone = lazy(() => import('../auth/nophone'))
const NoReg = lazy(() => import('../auth/noreg'))
const NoReset = lazy(() => import('../auth/noreset'))
const Order = lazy(() => import('../portals/order'))
const OrderView = lazy(() => import('../auth/orderview'))

const Shop = lazy(() => import('../shop'))
const TOS = lazy(() => import('../info/tos'))
const Unsub = lazy(() => import('../auth/unsub'))
const UpdateOrder = lazy(() => import('../portals/updateorder'))

export default function Routes () {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route
          path='/account'
          name='Account'
          component={Account} />
        <Route
          path='/address'
          name='Address'
          component={Address} />
        <Route
          path='/bottleshops'
          name='Shops'
          component={BottleShops} />
        <Route
          path='/bottle/:slug/:bottleslug'
          name='Bottle'
          component={Bottle} />
        <Route
          path='/cart'
          name='Cart'
          component={CartView} />
        <Route
          path='/checkout'
          name='Checkout'
          component={Checkout} />
        <Route
          path='/comingsoon/confirm/:emailhash'
          name='Coming Soon Confirm'
          component={ComingSoonConfirm} />

        {/*Future invites need to We need to use this endpoint*/}
        <Route
          path='/csunsub/:emailhash'
          name='Coming Soon Unsub'
          component={ComingSoonUnsub} />

        <Route
          path='/driveup'
          name='Driver'
          component={GetPaid} />
        <Route
          path='/forgot'
          name='Forgot Form'
          component={ForgotForm} />
        <Route
          path='/help/tos'
          name='TOS'
          component={TOS} />
        <Route
          path='/help/faq'
          name='Help'
          component={HelpFaq} />
        <Route
          path='/help/contact'
          name='Contact'
          component={ContactUs} />
        <Route
          path='/help/hours'
          name='Hours&Contact'
          component={HoursContact} />
        <Route
          path='/hours'
          name='Delivery Hours'
          component={HoursOfOperation} />
        <Route
          path='/invite/:emailhash'
          name='Invite User'
          component={ComingSoonInvite} />
        <Route
          path='/landing'
          name='Home'
          component={Landing} />
        <Route
          path='/producer/:category/:oid/:pid'
          name='LCBO Producer'
          component={LProducer}
        />
        <Route
          path='/lcbo/:category'
          name='LCBO Products'
          component={LcboRegPrice}
        />
        <Route
          path='/sale/:category'
          name='LCBO Sales by Category'
          component={LcboSale}
        />
        <Route
          path='/product/:category/:lcboid'
          name='LCBO Product'
          component={LcboProduct}
        />
        <Route
          path='/lcbo/prod/:catid/:lcboid'
          name='LCBO Product'
          component={LcboProduct}
        />
        <Route
          path='/lcbo/:catName/:lcboId'
          name='Lcbo Blog Product'
          component={LcboBlogProduct}
        />
        <Route
          path='/lcbo'
          name='Lcbo'
          component={LcboBeta}
        />
        <Route
          path='/login'
          name='Login'
          component={Login} />
        <Route
          path='/noreset/:code'
          name='Did Not Reset'
          component={NoReset} />
        <Route
          path='/noreg/:code'
          name='Did Not Register'
          component={NoReg} />
        <Route
          path='/nophone/:code'
          name='Wrong Number'
          component={NoPhone} />
        <Route
          path='/orders'
          name='Orders'
          component={Carts} />
        <Route
          path='/order/:ott/:cid/:ehash?'
          name='Order'
          component={Order} />
        <Route
          path='/orderupdate/:ott/:cid/:ehash?'
          name='Update Order'
          component={UpdateOrder} />
        <Route
          path='/ordercancel/:ott/:cid/:ehash?'
          name='Cancel Order'
          component={CancelOrder} />
        <Route
          path='/order'
          name='Order View'
          component={OrderView} />
        <Route
          path='/reset/:ttcode/:emailhash'
          name='Reset Click'
          component={ResetClick} />
        <Route
          path='/shop/:slug'
          name='Bottle Shop'
          component={Shop} />
        <Route
          path='/signout'
          name='Signout'
          component={Signout} />
        {/* <Route
          path='/signup/:convcode'
          name='Convert'
          component={SignupHandler} /> */}
        <Route
          path='/signup'
          name='Signup'
          component={SignupHandler} />


        <Route
          path='/phoneverify/:code/:emailhash'
          name='Verify Phone Number'
          component={PhoneVerify} />


        {/*Temp */}
        <Route
          path='/comingsoon/unsub/:emailhash'
          name='Unsubscribe'
          component={Unsub} />
        <Route
          path='/unsub/:emailhash'
          name='Unsubscribe'
          component={Unsub} />
        <Route
          path='/verify/:emailhash'
          name='Verify'
          component={VerifyClick} />
        {/* Keep Home and root routes at bottom */}
        <Route
          path='/home'
          name='Home'
          component={Landing} />
        <Route
          path='/'
          name='Home'
          component={Landing} />
      </Switch>
    </Suspense>
  )
}