import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { ChevronLeft } from '../icons'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const Back = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(grey[500]),
    borderColor: grey[400],
    '&:hover': {
      borderColor: grey[900],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: grey[50],
      color: grey['A400'],
    }
  },
  text: {
    color: grey[500],
    fontStyle: 'courrier',
    fontWeight: 'bold',
    letterSpacing: 2,
    textTransform: 'none',
  },
  link: {
    textDecoration: 'none',
    textDecorationColor: grey[200],
  },
}))

// converted to a back button using useHistory
export default function BackBtn({
  backTxt
}) {
  const classes = useStyles()
  const history = useHistory()
  return (
      <Back
        onClick={() => history.goBack()}
        aria-label='Back Button'
        className={classes.button}
        variant='outlined'
      >
        <span className={classes.text}>
          <ChevronLeft icon='left' right={3} L />
          {
            !backTxt ? null :
            <span >
              {backTxt}&nbsp;
            </span>
          }
        </span>
      </Back>
  )
}