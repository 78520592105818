import Stage from '../util/stage'
import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import { StatusCodes as http } from 'http-status-codes'
import { Tart } from '../../utility'

// general unsub
export default async function unsub(dispatch, emailhash) {
  const action = 'unsub'
  Stage.init(action, dispatch)
  const url = Express.unsub

  const [err, resp] = await to (
    axios({
      data: { emailhash },
      method: 'DELETE',
      url,
    })
  )

  if (err) {
    Stage.err(action, dispatch, err)
    const { status } = err.response
    if (status === http.CONFLICT) {
      Tart.warn('You are already unsubscribed')
    } else if (status === http.NOT_FOUND) {
      Tart.warn('We could not find you')
    } else {
      Tart.warn('Unsubscribe failed. Contact beer@craftdelivery.ca to unsubscribe')
    }
    return
  }
  Tart.info('Sorry to see you go. You are now unsubscribed')
  Stage.ok(action, dispatch, resp)
}