const Msgs = {
  termsNotMet: 'You have not agreed to or do not meet the terms of service',
  badPwd: 'Passwords do not match',
  badEmail: 'Invalid email',
  noFn: 'Please provide your first name',
  noLn: 'Please provide your last name',
  human: 'Robot warning: humans only.',
  login: {
    incorrect: 'Incorrect Password',
    emailNotFound: 'Email Not Found',
    deactivated: 'Your account seems to be deactivated. Email us at ',
    noauth: 'We could not authenticate you, please login first, then try clicking the link from your email',
    sessionConflict: 'It looks like somebody else has logged in using this browser. Ensure other this user is logged out, then thy loggin in and clicking the link',
    sessionExpired: 'Your session has expired. Please login'
  }
}

export default Msgs
