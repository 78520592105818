import * as A from '../actions'
import Stage from '../util/stage'
import { Express } from '../../config'
import { axUser } from '../config/ax'
import to from 'await-to-js'
import { Tart, tooSoon } from '../../utility'
// import isEqual from 'lodash.is-equal'

// TODO: (use these checks to disable the submit button as well)
//   - compare inconing with current
//   - compare timestamp
export default async(dispatch, address, addressCurrent, timestamp) => {
  // use this to also disable the submit button
  // if (is-Equal(address, addressCurrent)) {
  //   return
  // }

  // todo determine equality without lodash dep

  const { allow, seconds } = tooSoon.lockout(timestamp)
  if (!allow) {
    Tart.warn(`Please wait ${seconds} seconds before updating again`)
    return
  }
  const action = 'addressSubmit'
  Stage.init(action, dispatch)

  const [err, resp] = await to(
    axUser.put(Express.address, { address })
  )
  if (err) {
    console.log(err)
    if (err.response && err.response.status) {
      Tart.err(`Error updating adddress. status: ${err.response.status}`)
    } else {
      Tart.err('There was an error updating your address')
    }
    Stage.err(action, dispatch, err)
    return
  }
  Tart.ok('Address updated!')
  dispatch({
    type: A.address.update,
    address,
  })
  Stage.ok(action, dispatch, resp)
}