import React, { useEffect, useState } from 'react'
import { Cog } from '../icons'
import { LcboButton } from '../buttons'
import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import { formatAbv, formatPrice, lcboImgUrl } from '../../utility'
import MainDiv from '../maindiv'

export default function BlogProduct({ match }) {
  const {
    // catName,
    lcboId,
  } = match?.params

  const [data, setData] = useState(null)

  useEffect(() => {
    if (!data) {
      const getData = async() => {
        const url = `${Express.lcboProduct}/${lcboId}`
        const [err, resp] = await to(axios.get(url))
        if (err) {
          console.log(err)
        } else {
          setData(resp.data.product)
        }
      }
      getData()
    }
  }, [data, lcboId])


  const img = lcboImgUrl(lcboId, 720)
  const name = data && data.name && decodeURIComponent(data.name)
  return (
    <MainDiv>
      <div>
        <Cog show={!data} />
      </div>
      <div>
        <LcboButton />
      </div>
      {!data ? null : (
        <div>
          <h6>
            We apologize that our blog is out of date with current inventories
          </h6>
          <h4>
            {formatAbv(data.abv)} | {formatPrice(data.price)}
          </h4>
          <h3>
            {name}
          </h3>
          <h4>
            {decodeURIComponent(data.producer)}
          </h4>
          <h5>
            {decodeURIComponent(data.cat)} | {decodeURIComponent(data.cat2)}
          </h5>
          <h6>
            {decodeURIComponent(data.varietal)} | {decodeURIComponent(data.volml)}mL
          </h6>
          <div>
            <img src={img} width='234' height='234' alt={name}/>
          </div>
        </div>
      )}
    </MainDiv>
  )
}