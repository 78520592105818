Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tos = exports.refund = exports.privacy = exports.contact = undefined;

var _contact = require('./snippets/contact');

var _contact2 = _interopRequireDefault(_contact);

var _privacy = require('./privacy');

var _privacy2 = _interopRequireDefault(_privacy);

var _refund = require('./refund');

var _refund2 = _interopRequireDefault(_refund);

var _tos = require('./tos');

var _tos2 = _interopRequireDefault(_tos);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.contact = _contact2.default;
exports.privacy = _privacy2.default;
exports.refund = _refund2.default;
exports.tos = _tos2.default;