import { storageKeys as keys } from '../config'
import getItem from './getitem'
import setItem from './setitem'

export default {
  keys,
  getKeys: () => Object.values(keys),
  getItem,
  setItem,

  // don't erase these keys on logout or clear
  keep: [
    keys.comingSoonEmailSent,
    keys.email,
    keys.geo,
    keys.lastLogin,
    keys.region,
  ]
}