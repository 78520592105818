import { ActionType, createActionTypes } from 'action-types'

// data should stay together
// maybe this reducer should cover the searchbar:
//  - value and state
const actions = createActionTypes({
  search: {
    catcall: ActionType,
    clear: ActionType,
    enable: ActionType,
    disable: ActionType,
    hide: ActionType,
    lcbo: ActionType,
    show: ActionType,
    value: ActionType,

    // search by: not sure if each should go in its own reducer
    //  ie: users reducer...
    //
    usersByEmail: ActionType,
    usersById: ActionType,
    usersFiltered: ActionType,
    usersClearFilter: ActionType,

    // replace above with below
    // subkey search functions:
    users: {
      email: ActionType,
      id: ActionType,
      filtered: ActionType,
      clear: ActionType,
    },
  }
})
const _actions = actions.search
export default _actions
