import axios from 'axios'
import * as A from '../actions'
import to from 'await-to-js'
import { Express } from '../../config'

// NOTE: legacyCallback is temporary while old admin views are still around
// TOD REFACTOR SERVER SIDE
export default async(dispatch) => {
  const [err, resp] = await to(
    axios.get(Express.imgUrls)
  )
  if (err) {
    console.log(err)
    return
  }
  const {
    beers,
    urlRoot,
  } = resp.data
  dispatch({ type: A.img.beers, beers })
  dispatch({ type: A.img.urlRoot, urlRoot })
}
