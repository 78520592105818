import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { ChevronLeft } from '../icons'

const style = makeStyles({
  root: {
    color: 'lightgrey',
    marginTop: '5px',
    marginBottom: '5px',
  }
})

export default () => 
  <Link className='no-dec-link' to='/lcbo'>
    <Button
      variant='outlined'
      className={style().root}
    >
      <ChevronLeft L />
      LCBO
    </Button>
  </Link>