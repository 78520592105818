import owasp from 'owasp-password-strength-test'
import { constants } from '../config'

const { PWD_MIN, PWD_MAX, PWD_TESTS } = constants

// const maxLength = 32
// const minLength = 8
export default pwd => {
  owasp.config({
    allowPassphrases: false,
    maxLength: PWD_MAX,
    minLength: PWD_MIN,
    minOptionalTestsToPass: PWD_TESTS,
  })
  const test = owasp.test(pwd)
  return test
}

// https://stackoverflow.com/a/12090265
// https://github.com/nowsecure/owasp-password-strength-test/blob/master/README.md
