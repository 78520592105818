import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  address: {
    loadData: ActionType,
    update: ActionType,
    clearData: ActionType,
  }

})
const _actions = actions.address
export default _actions
