import React from 'react'
import BaseIcon from './baseicon'

export default function PlusIcon(props) {
  return props.hide ?
    null :
    <BaseIcon
      {...props}
      icon='plus'
    />
}