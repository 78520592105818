import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  lcbobeta: {
    // are we using catid here?
    catId: ActionType,
    cat2Id: ActionType,
    cat2IdReset: ActionType,
    cat2ByCat: ActionType,
    setCategory: ActionType,
    preload: ActionType,

    //replace products with flat lcatagg and lprods
    // overwrite lcatagg on region change
    // merge data in to lprods
    // stash timestamp so no refetch unless stale
    lCatAgg: ActionType,
    lProds: ActionType,
    // products: ActionType,

    prodInProgress: ActionType,
    page: ActionType,
    sale: ActionType,
    scamOpen: ActionType, // pass in price 
    // scamPrice: ActionType,
    scamClose: ActionType, // close and set price to zero
  }
})
const _actions = actions.lcbobeta
export default _actions
