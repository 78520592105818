import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  regions: {
    subdomain: ActionType,
    areas: ActionType,
    current: ActionType,
    setCurrent: ActionType,
    list: ActionType,
    invFresh: ActionType,
    initRegion: ActionType,
  }
})
const _actions = actions.regions
const regionsValues = Object.values(_actions)
export default _actions
export { regionsValues }
