import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

const Danger = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    borderColor: red[400],
    '&:hover': {
      borderColor: red[1700],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  text: {
    color: red[500],
    fontWeight: 'bold'
  }
}))

export default function DangerBtn(props) {
  const classes = useStyles()

  return (
    <Danger {...props}>
      <span className={classes.text}>
        {props.children}
      </span>
    </Danger>
  )
}