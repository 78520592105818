const useLocalLam = process.env.REACT_APP_USE_API_GATEWAY_LOCAL === '1'
const lambdaUrl = useLocalLam ? process.env.REACT_APP_API_GATEWAY_LOCAL : process.env.REACT_APP_API_GATEWAY

export default class Urls {
  static A = () => {
    return {
        root: process.env.REACT_APP_ROOT,
        webroot: process.env.REACT_APP_WEBROOT,
        _root: process.env.REACT_APP_ROOT_,
        _webroot: process.env.REACT_APP_WEBROOT_,
    }
  }
  static R = Urls.A().root
  static W = Urls.A().webroot
  static _R = Urls.A()._root
  static _W = Urls.A()._webroot

  // todo get rid of these??
  static img = `${Urls.W}img/`
  static regions = `${Urls.W}img/regions/`

  static make = (route) => `${Urls._R}/${route}`

  static lambda = (route) => `${lambdaUrl}${route}`

  // chld=ski|bb|Wheeee!|FFFFFF|000000
  // https://developers.google.com/chart/image/docs/gallery/dynamic_icons
  static pin = 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld='
  static bubble = 'https://chart.apis.google.com/chart?chst=d_bubble_icon_text_small&chld=beer|bb|'
  static bubCol = '|FFFFFF|000000'

  // insert letter betwen pin and greenPin
  static green = '|ADDE63'
 }
