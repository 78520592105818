export default {
  time: {
    loginTime: undefined,
    isMidnight: false,
    ticksToMidnight: undefined,
    ticksToLcbo: undefined,
    ticksToBeerStore: undefined,
    ticksToCraft: undefined,
  }
}
