Object.defineProperty(exports, "__esModule", {
  value: true
});

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Obj = function Obj() {
  _classCallCheck(this, Obj);
};

Obj.modifiedKeys = function (referenceObject, comparisonObject) {
  var differenceObject = Obj.modifiedPairs(referenceObject, comparisonObject);
  return Object.keys(differenceObject);
};

Obj.modifiedPairs = function (referenceObject, comparisonObject) {

  var referenceKeys = Object.keys(referenceObject);

  var differenceObject = referenceKeys.filter(function (item) {
    return comparisonObject[item] !== referenceObject[item];
  }).reduce(function (obj, key) {
    obj[key] = referenceObject[key];
    return obj;
  }, {});

  return differenceObject;
};

Obj.flattenObject = function (object, key) {
  var flatObj = {};
  var keys = Object.keys(object);
  keys.forEach(function (k) {
    flatObj[k] = Obj.flattenArray(object[k], key, true);
  });
  return flatObj;
};

Obj.flattenArray = function (a, key) {
  var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  //console.log(array)
  var flat = a.map(function (o) {
    return o[key];
  });
  if (sort) {
    return flat.sort(function (a, b) {
      return a - b;
    });
  }
  return flat;
};

Obj.uniq = function (o, key) {
  var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var restrictCat = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'cat';
  var restrictTo = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;


  var filtered = void 0;
  if (restrictTo !== null) {
    filtered = o.filter(function (i) {
      return i[restrictCat] === restrictTo;
    });
  } else {
    filtered = o;
  }

  var u = [].concat(_toConsumableArray(new Set(filtered.map(function (item) {
    return item[key];
  })))).filter(function (notnull) {
    return notnull !== null;
  });
  if (sort) {
    return u.sort();
  } else {
    return u;
  }
};

Obj.simpleSum = function (o) {
  var s = 0;
  Object.keys(o).forEach(function (id) {
    s += o[id];
  });
  return s;
};

Obj.sum = function (o) {
  var s = 0;
  Object.keys(o).forEach(function (id) {
    s += o[id].quantity;
  });
  return s;
};

exports.default = Obj;