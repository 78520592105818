import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import { Cart } from '../icons'

const KeepShopping = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    borderColor: green[400],
    '&:hover': {
      borderColor: green[900],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: green[50],
      color: green['A400'],
    }
  },
  link: {

    textDecorationColor: green[200],
  },
  text: {
    color: green[500],
    fontWeight: 'bold',
    textTransform: 'none',
  }
}))

export default function KeepShoppingBtn(props) {
  const classes = useStyles()
  const history = useHistory()
  return (
    <KeepShopping
      onClick={() => history.goBack()}
      className={`${classes.button} checkout-button`}
      variant='outlined'
      {...props}
    >
      <span className={classes.text}>
        <Cart L color={green['A700']} />
        Keep Shopping
      </span>
    </KeepShopping>
  )
}
