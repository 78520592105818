// replaces lprod

import axios from 'axios'
import to from 'await-to-js'
import { Tart } from '../../utility'
import { Express } from '../../config'
import { actions } from '../index'

// TODO: determine if we are alling on region change as well
export default async function lCatAgg(dispatch, rid) {

  const url = `${Express.lCatAgg}/${rid}`
  const [err, resp] = await to(axios.get(url))

  if (err) {
    console.log(err)
    Tart.warn('Unable to load lcbo products [lCatAgg]')
    return
  }
  dispatch({
    type: actions.lcbobeta.lCatAgg,
    value: resp.data,
  })
}