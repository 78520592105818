import React from 'react'
import Icon from '../icon'
import getColor from './getcolor'

export default function BaseIcon(props) {
  if (props.hide) return null
  const {
    double, D,
    large, L,
    left,
    right,
    triple, T,
  } = props
  return (
    <Icon
      {...props}
      double={double || D ? true : false}
      large={large || L ? true : false}
      triple={triple || T ? true : false}
      padLeft={left || 0}
      padRight={right || 2}
      color={getColor(props)}
    />
  )
}