export default function binarySearch(array, target) {
  let startIndex = 0
  let endIndex = array.length - 1

  while(startIndex <= endIndex) {
    let middleIndex = Math.floor((startIndex + endIndex) / 2)
    if(target === array[middleIndex]) {
      return true
    }
    if(target > array[middleIndex]) {
      startIndex = middleIndex + 1
    }
    if(target < array[middleIndex]) {
      endIndex = middleIndex - 1;
    }

  }
  
  return false
}