import init from './init/delivery'
import actions, { deliveryValues } from './actions/delivery'

export default (state=init, action) => {
  if (action.type === actions.noContent) {
    return {
      ...state,
      delivery: {
        ...state.delivery,
        noContent: true,
      }
    }
  } else if (action.type === actions.daysOpenEdit) {
    const {
      closetime,
      dayofweek,
      open,
      opentime,
      region_id,
    } = action
    const { delivery } = state
    return {
      ...state,
      delivery: {
        ...delivery,
        daysOpen: {
          ...delivery.daysOpen,
          [region_id]: {
            ...delivery.daysOpen[region_id],
            [dayofweek]: {
              ...delivery.daysOpen[region_id][dayofweek],
              closetime,
              open,
              opentime,
            }
          }
        }
      }
    }
  } else if (action.type === actions.addDaysClosed) {
    const { delivery } = state
    return {
      ...state,
      delivery: {
        ...delivery,
        daysClosed: [
          ...delivery.daysClosed,
          action.day,
        ]
      }
    }
  } else if (action.type === actions.removeDaysClosed) {
    const { delivery } = state
    return {
      ...state,
      delivery: {
        ...delivery,
        daysClosed: delivery.daysClosed.filter(day =>
          !(day.month === action.day.month &&
            day.day === action.day.day &&
            day.year === action.day.year)
        )
      }
    }
  } else if (action.type === actions.pricing) {
    return {
      ...state,
      delivery: {
        ...state.delivery,
        pricing: action.pricing,
      }
    }
  } else if (deliveryValues.includes(action.type)) {
    const a = action.type.replace('delivery.', '')
    return {
      ...state,
      delivery: {
        ...state.delivery,
        [a]: action.value,
      }
    }
  } else {
    if (action.type.startsWith('delivery')) {
      console.warn('delivery reducer default')
    }
    return state
  }
}
