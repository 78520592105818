export default col => {
  let text
  switch (col) {
    case 'info':
      text = 'Open Later'
      break
    case 'success':
      text = 'Open'
      break
    case 'warning':
      text = 'Closed for the Day'
      break
    case 'danger':
      text = 'Closed'
      break
    default:
      col = 'secondary'
      text = 'Closed'
  }
  return {
    colClass: `text-${col}`,
    text
  }
}