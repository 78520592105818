
import { genCraftUrl } from '../img'

export default function craftImgUrl(beers, imgType, sid, id, urlRoot) {
  if (!beers || !beers[id]) {
    return genCraftUrl(imgType, sid, id)
  }
  const beer = beers[id]
  if (!beer) return ''
  const { filename, imgtype, midpath, params, size, uid } = beer
  const { cdn, defaultSize, root } = urlRoot[uid]

  if (cdn === 'squarespace') {
    const ss = defaultSize ? `${defaultSize}w` : '300w'
    return `${root}${midpath}${filename}.${imgtype}?format=${ss}`
  } else if (cdn === 'shopify') {
    const ss = defaultSize ? `_${defaultSize}x` : '_250x250'
    const version = (params && params.v) ? `?v=${params.v}` : ''
    const iu = `${root}${filename}${ss}.${imgtype}${version}`
    return iu
  } else if (cdn === 'uploads') {
    // this covers jcp, halo and high park
    const iu = `${root}${midpath || ''}${filename}.${imgtype}`
    return iu
  } else if (cdn === 'wp') {
    const ss = !size ? `-${defaultSize}x${defaultSize}` : `-${size}x${size}`
    const iu = `${root}${midpath || ''}${filename}${ss}.${imgtype}`
    return iu
  } else {
    return `${root}${filename}.${imgtype}`
  }
}
