import init from './init/search'
import actions from './actions/search'

// TODO / TBD do we need to close the sidebar on these ops?
export default (state=init, action) => {
  switch (action.type) {

    // this is an artifact of the callback hack we will no longer be using
    // TODO get rid of it
    case actions.catcall: {
      // if (state.search.catCallback) {
      //   const { category } = action
      //   state.search.catCallback(category)
      // }
      return {
        ...state,
        search: {
          ...state.search,
          value: '',
        }
      }
    }
    case actions.disable: {
      return {
        ...state,
        search: {
          ...state.search,
          callback: undefined,
          enable: false,
          show: false,
        }
      }
    }
    case actions.lcbo: {
      //close_idebar()
      return {
        ...state,
        search: {
          ...state.search,
          // callback: action.callback,
          // catCallback: action.catCallback,
          enable: true,
          show: true,
        }
      }
    }
    case actions.enable: {
      // close_idebar()
      return {
        ...state,
        search: {
          ...state.search,
          // callback: action.callback,
          enable: true,
        }
      }
    }
    case actions.show: {
      // close_idebar()
      return {
        ...state,
        search: {
          ...state.search,
          show: true,
        }
      }
    }
    case actions.hide: {
      return {
        ...state,
        search: {
          ...state.search,
          show: false,
        }
      }
    }
    case actions.clear: {
      // closeSidebar()
      // TEMP for lcboproducts. TODO fix refactor LcboProducts
      // if (state.search.callback) {
      //   state.search.callback('')
      // }
      return {
        ...state,
        search: {
          ...state.search,
          value: '',
        }
      }
    }
    case actions.value: {
      //closeidebar()
      // TEMP for lcboproducts. TODO fix refactor LcboProducts
      // if (state.search.callback) {
      //   state.search.callback(action.value)
      // }
      return {
        ...state,
        search: {
          ...state.search,
          value: action.value,
        }
      }
    }
    default: {
      if (action.type.startsWith('search')) {
        console.warn('search reducer default')
      }
      return state
    }
  }
}
