// validated local storage key
import { storageKeys } from '../config'

export default (key, label, value=null) => {
  if (key === undefined || key === null) {
    return false
  }
  const keyType = typeof(key)
  if (!['boolean', 'string'].includes(keyType)) {
    console.group(label)
    console.warn(`Key must be of type string, got type: ${keyType}`)
    console.warn(key)
    console.groupEnd(label)
    return false
  }
  if (!Object.keys(storageKeys).includes(key)) {
    console.group(label)
    console.warn(storageKeys)
    console.warn(`Invalid key: ${key}`)
    console.groupEnd(label)
    return false 
  }
  return true
}