/*
enum address_type = {primary, secondary, billing}
*/
// const newAddressInit = {
//   id: '',
//   user_id: '',
//   active: '',
//   postal: '',
//   lat: '',
//   lng: '',
//   city: '',
//   locality: '',
//   line_1: '',
//   line_2: '',
//   unit: '',
//   buzzer: '',
//   notes: '',
// }
const addressInitialState = {
  // leave billing to stripe, this is an ontario shipping address
  address: {
    // load: newAddressInit,
    addresses: {},
    primaryId: null,
  },
}
// const getNewAddress = () => {
//   return Object.assign({}, newAddressInit)
// }
export default addressInitialState
// export { getNewAddress }
