import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import * as A from '../actions'
import LogRocket from 'logrocket'
import { env } from '../../config'

export default async(dispatch) => {
  if (env.IS_LOCAL) return
  const { ping } = Express

  const [err, resp] = await to (axios.get(ping))

  if (err) {
    console.log(err)
    return
  }
  const { ip } = resp.data
  dispatch({
    type: A.session.pingIp,
    ip,
  })
  LogRocket.identify(ip, {ip})
}