const tms = s => s / 1000 / 60 / 60

export default timestamp => {
  const now = Date.now()
  if (!timestamp) return { now }
  const delta = now - timestamp
  const h = Math.floor(tms(delta))
  const m = Math.floor((tms(delta) - h) * 60) 
  const s = Math.floor(((tms(delta) -h) * 60 -m) *60)
  let niceTime = `${h}h:${m}m:${s}s`

  return {
    delta,
    h,
    m,
    niceTime,
    now,
    s,
  }
}