import Urls from './urls'

// comment out unused routes
export default {
  /*  initClient combines in a single POST request:
        - regions
        - schedule
        - timeslots
  */
  byCat: Urls.lambda('bycat'),

  initClient: Urls.lambda('init'),

  daysOpen: Urls.lambda('days'),
  dropdowns: Urls.lambda('bottledropdowns'),
  // inventories: Urls.lambda('inventory'),
  invRegional: Urls.lambda('inv'),
  lookups: Urls.lambda('lookups'),
  lookups2: Urls.lambda('lookups2'),
  regions: Urls.lambda('regions'),
  schedule: Urls.lambda('schedule'),
  timeslots: Urls.lambda('timeslots'),
  timeslotsEst: Urls.lambda('timeslotsest'),
  units: Urls.lambda('units'),
}
