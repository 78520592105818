import React, {
  useState,
} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { getProgress } from '../img'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  content: {
    maxWidth: 350,
    textAlign: 'center',
  },
  title: {
    lineHeight: 1.0,
    textAlign: 'center',
  },
  titleUpdates: {
    color: 'green',
  },
}))


export default function Beerify() {
  const [open, setIsOpen] = useState(true)
  const classes = useStyles()
  return (
    <Dialog
      open={open}

      disableEscapeKeyDown={false}
    >
      <DialogTitle
        disableTypography={true}
        className={classes.title}
      >
        <div
          id='cy-age-dialog-title-text'
          className={classes.content}
        >
          Craft Delivery is no longer in operation. Our new brand Beerify is under construction
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img
          alt='Dave Murray Animated GIF'
          className='intro-gif'
          src={getProgress({ext: 'gif'})}
        />
      </DialogContent>
      <DialogActions>
      <Button
          variant='outlined'
          color='secondary'
          onClick={() => setIsOpen(false)}
        >
          Got It
        </Button>
      </DialogActions>
    </Dialog>
  )
}