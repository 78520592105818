import actions from './actions/bottleshop'
import { init } from './init/bottleshop'

export default (state=init, action) => {
  switch(action.type) {
    case actions.initNewShopUnits: {
      const { shopId, bottleId } = action

      if (!state?.bottleshop?.units || !(shopId in state.bottleshop.units)) {
        return {
          ...state,
          bottleshop: {
            ...state.bottleshop,
            units: {
              ...state.bottleshop.units,
              [shopId]: {}
            }
          }
        }
      }

      const units = {...state.bottleshop.units[shopId]}
      if (units[bottleId]) {
        return state
      }
      return {
        ...state,
        bottleshop: {
          ...state.bottleshop,
          units: {
            ...state.bottleshop.units,
            [shopId]: {
              ...state.bottleshop.units[shopId] || {},
              [bottleId]: []
            }
          },
        }
      }
    }
    // we want to start de-nesting rid if possible
    // unless we use the keys to populate, nesting is not
    // req'd and results in dupe data
    case actions.preload: {
      const { preload, rid } = action
      return {
        ...state,
        bottleshop: {
          ...state.bottleshop,
          preload: {
            ...state.bottleshop.preload,
            [rid]: preload,
          },
        }
      }
    }
    case actions.shopUrls: {
      const { shopUrls } = action
      return {
        ...state,
        bottleshop: {
          ...state.bottleshop,
          shopUrls: {
            ...state.bottleshop.shopUrls,
            ...shopUrls
          }
        }
      }
    }
    case actions.showBuyDirectInfo: {
      return {
        ...state,
        bottleshop: {
          ...state.bottleshop,
          showBuyDirectInfo: action.value,
        }
      }
    }
    case actions.initNewBottleUnits: {
      return {
        ...state,
        bottleshop: {
          ...state.bottleshop,
          units: {
            ...state.bottleshop.units,
            [action.shop_id]: {}
          },
          unitDetailsById: {
            ...state.bottleshop.unitDetailsById,
            [action.shop_id]: {}
          },
        }
      }
    }
    // et: inventories should be added not replaced
    case actions.inventories: {
      return {
        ...state,
        bottleshop: {
          ...state.bottleshop,
          inventories: {
            ...state.bottleshop.inventories,
            ...action.value
          }
          
        }
      }
    }
    case actions.setAll: {
      const { value } = action
      return {
        ...state,
        bottleshop: {
          ...state.bottleshop,
          shopsById: value.shopsById,
          shopHours: value.shopHours,
          shopIdsBySlug: value.shopIdsBySlug,
          sortOrder: value.sortOrder,
          sortedShopIds: value.sortedShopIds,
          time_last_mod: value.time_last_mod,
          time_mod_userid: value.time_mod_userid
        }
      }
    }
    // case actions.reset: {
    //   return {
    //     ...state,
    //     bottleshop: undefined
    //   }
    // }
    // case actions.clearAll: {
    //   return {
    //     ...state,
    //     bottleshop: {
    //       ...state.bottleshop,
    //       form: getNewShop()
    //     }
    //   }
    // }
    case actions.setUnits: {
      return {
        ...state,
        bottleshop: {
          ...state.bottleshop,
          units: action.units,
          unitDetailsById: action.unitDetailsById,
        }
      }
    }

    default: {
      if (action.type.startsWith('bottleshop')) {
        console.warn('bottleshop reducer default')
      }
      return state
    }
  }
}
