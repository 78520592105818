import { Express } from '../../config'
import axios from 'axios'
import to from 'await-to-js'
import actions from '../actions/delivery'

export default async function pricing(dispatch) {
  const [err, resp] = await to(axios.get(Express.pricing))
  if (err) {
    console.log(err)
    return
  }
  dispatch({
    type: actions.pricing,
    pricing: resp.data,
  })
}
