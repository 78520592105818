import { Express } from '../config'
import axios from 'axios'
import to from 'await-to-js'
import Tart from './tart'

export default async(page, subject='', pop=false) => {
  const [e, ] = await to(
    axios.post(Express.ping, { page, subject })
  )
  if (e) {
    console.log(e)
    if (pop) {
      Tart.err('Error pinging admin')
    }
    return
  }
  if (pop) {
    Tart.ok('Ping OK')
  }
}
