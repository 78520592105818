import { Lambda } from '../../config'
import axios from 'axios'
import * as A from '../actions'
import { delivery } from '../actions'
import to from 'await-to-js'
import { getEstHours } from '../../@craft-delivery/cfg/dist/util'

// TBD: DO WE CALL THIS ON CHECKOUT TO GET THE LATEST???

// this will depend on the new daysopen daysclosed tables
// - also by region
// TODO: return for all regions...

// done in Lambda.initClient
export default async(dispatch) => {
  const est = getEstHours() + 100
  const url = `${Lambda.timeslotsEst}/${est}`
  const [e, resp] = await to(axios.get(url))
  if (e) {
    console.log(e)
    return
  }
  const { slots } = resp.data
  dispatch({
    type: delivery.available,
    value: slots,
  })
  const slotIds = Object.keys(slots)
  if (slotIds.length) {
    const timeslot = slots[slotIds[0]]
    dispatch({
      type: A.cart.setTimeslot,
      value: timeslot,
    })
  }
}
