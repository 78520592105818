const init = 'SELECT'
const initCat2 = 'All'
const pageInit = 150

export default {
  lcbobeta: {
    // ids on: [rid] --> [catid]
    preload: {},

    // replace products
    // just all ids regardless of cat
    lProds: {},
    // UI works of lcatagg and looks up in lprods 
    lCatAgg: {},

    // products: {},
    prodCount: {},
    // needs to be an object on catId...
    prodLastFetch: {},
    prodInProgress: false,
    initSelect: init,
    initSelectCat2: initCat2,
    category: '',
    catId: init,
    cat2Id: initCat2,
    cat2ByCat: {
      '1': null,
      '2': null,
      '3': null,
      '4': null,
      '5': null,
      '6': null,
    },
    page: pageInit,
    sale: false,
    scamOpen: false,
    scamPrice: null,
    scamIsTotal: false,
    scamIsMobile: false,
  }
}