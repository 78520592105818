import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import { CreditCard } from '../icons'

const Checkout = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(grey[500]),
    borderColor: grey[400],
    '&:hover': {
      borderColor: grey[900],
    },
  },
}))(Button)

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: grey[50],
      color: grey['A400'],
    }
  },
  text: {
    color: grey[500],
    fontWeight: 'bold',
    textTransform: 'none',
  }
}))

export default function CheckoutBtn(props) {
  const classes = useStyles()

  return (
    <Checkout
      className={`${classes.button} checkout-button`}
      variant='outlined'
      {...props}
    >
      <span className={classes.text}>
        <CreditCard L color={grey['A700']} />
        Checkout
      </span>
    </Checkout>
  )
}
