import authTel from './authtel'
import timeToResend from '../util/timetoresend'

// TODO: examine weird flow
// auth: email, phone, phoneCode, uid
export default async(dispatch, auth, progress) => {
  if (progress) {
    const resend = timeToResend(progress.timestamp, 'resending text message')
    if (!resend) return
  }
  if (!progress.timestamp) {
    authTel(dispatch, auth)
    return
  }

  authTel(dispatch, auth)
}
