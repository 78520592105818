import actions from './actions/session'
import init from './init/session'
import jwtDecode from 'jwt-decode'
import { setItem, storageKeys as keys } from '../utility'

export default (state=init, action) => {
  if (action.type === actions.loginUser) {
    let dectok = false
    const { data } = action
    const { stripe, user } = data

    try {
      dectok = jwtDecode(user.token)
    } catch (e) {
      console.log(e)
      return state
    }
    const { exp, iat } = dectok
    const sessionData = {
      isLoggedIn: true,
      token: {
        t: user.token,
        iat,
        exp,
        issuedAt: new Date(iat * 1000),
        expiresAt: new Date(exp * 1000),
        valmins: (exp - iat) / 60,
      },
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      emailCode: user.emailCode,
      emailVerified: user.verified,
      hasAddress: action.hasAddress,
      invalid: false,  // TODO user.active??
      isDriver: user.isDriver,
      isAdmin: user.isAdmin,
      phoneVerified: user.phoneVerified,
      phone: user.phone,
      phoneCode: user.phoneCode,
      phoneField: user.phone,
      phoneOptOut: user.phoneOptOut,
      phoneUpdating: false, //TODO use progress
      stayLoggedIn: true,
      stripe,
      userId: user.userId,
    }
    setItem(keys.email, user.email)
    setItem(keys.sessionToken, user.token)
    return {
      ...state,
      session: {
        ...state.session,
        ...sessionData,
      }
    }
  } else if (action.type === actions.failedLogin) {
    console.log(action)
    return {
      ...state,
      session: {
        ...state.session,
        failedLogin: action.value,
      }
    }
  }
  
  // else if (action.type === actions.enableSocket) {
  //   return {
  //     ...state,
  //     session: {
  //       ...state.session,
  //       enableSocket: true,
  //     }
  //   }
  // } 
  // else if (action.type === actions.disableSocket) {
  //   return {
  //     ...state,
  //     session: {
  //       ...state.session,
  //       enableSocket: false,
  //     }
  //   }
  // }
  
  else if (action.type === actions.authId) {
    return {
      ...state,
      session: {
        ...state.session,
        authId: action.value,
      }
    }
  }
  else if (action.type === actions.setPhone) {
    return {
      ...state,
      session: {
        ...state.session,
        phone: action.value,
      }
    }
  } else if (action.type === actions.setPhoneUpdating) {
    return {
      ...state,
      session: {
        ...state.session,
        phoneUpdating: action.value,
      }
    }
  } else if (action.type === actions.setPhoneField) {
    return {
      ...state,
      session: {
        ...state.session,
        phoneField: action.phone,
      }
    }
  } else if (action.type === actions.resetPhoneField) {
    return {
      ...state,
      session: {
        ...state.session,
        phoneField: state.session.phone,
      }
    }
  } else if (action.type === actions.setPhoneVer) {
    return {
      ...state,
      session: {
        ...state.session,
        phoneVerified: action.value,
      }
    }
  } else if (action.type === actions.editPhoneModal) {
    return {
      ...state,
      session: {
        ...state.session,
        editPhoneModal: action.value,
      }
    }
  } else if (action.type === actions.hasAddress) {
    return {
      ...state,
      session: {
        ...state.session,
        hasAddress: action.value,
      }
    }
  } else if (action.type === actions.setEmailIsVerified) {
    return {
      ...state,
      session: {
        ...state.session,
        emailVerified: action.value,
      }
    }
  } else  if (action.type === actions.agreetos) {
    return {
      ...state,
      session: {
        ...state.session,
        agreetos: false,
      }
    }
  } else if (action.type === actions.resettos) {
    return {
      ...state,
      session: {
        ...state.session,
        agreetos: false,
      }
    }
  } else if (action.type === actions.stay) {
    return {
      ...state,
      session: {
        ...state.session,
        stayLoggedIn: true,
      }
    }
  } else if (action.type === actions.pingIp) {
    return {
      ...state,
      session: {
        ...state.session,
        ip: action.ip,
      }
    }
  } else if (action.type === actions.telVerify) {
    return {
      ...state,
      session: {
        ...state.session,
        phoneVerified: true,
      }
    }
  } else if (action.type === actions.logout) {
    return {
      ...state,
      session: {
        ...state.session,
        isLoggedIn: false,
        token: null,
        email: null,
        emailCode: null,
        emailVerified: null,
        failedLogin: null,
        firstName: '',
        lastName: '',
        phone: '',
        phoneCode: null,
        phoneVerified: false,
        phoneField: '',
        phoneUpdating: false,
        isDriver: false,
        isAdmin: false,
        loginCount: null,
        hasAddress: false,
        stayLoggedIn: false,
        useStripeProd: false,
        sPublicProd: null,
        sPublicTest: null,
        userId: null,
        invalid: false,
      }
    }
  } else {
    return state
  }
}
