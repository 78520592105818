import groupBy from 'lodash.groupby'
import { InvoiceDetails } from '../../@craft-delivery/cfg/dist/invoice'
import sh from '../../@craft-delivery/cfg/dist/util/string'
import { constants } from '../../config'

const {
  SCAM_BOOZER_PERCENT,
  SCAM_RUNNER_PERCENT,
} = constants 

export let makeInvoice = (
  cart,
  sortedShopIds,
  lProds,
  pricing,
) => {
  let runnerScam = 0
  let boozerScam = 0
  let craftTotal = 0
  let craftHst = 0
  let shopTotals = {}
  let shopHst = {}
  const craft = groupBy(cart.craft, x => x.shopId)
  const shopIds = Object.keys(craft).map(id=>parseInt(id, 10))

  const lcboStop = cart.lcboCount <= 0 ? 0 : 1
  const stops = shopIds.length
  const totalStops = lcboStop + stops
  if (!totalStops) return null

  const lcboDelivery = lcboStop * InvoiceDetails.lcboDelivery
  const lcboDeliveryHst = sh.roundFloat(lcboDelivery * InvoiceDetails.hstRate)

  const delivery = sh.roundFloat((pricing[stops] * stops) / 100)
  const deliveryDiscount = sh.roundFloat((pricing[1] / 100) * stops - delivery)
  const craftDeliveryHst = delivery * InvoiceDetails.hstRate

  const deliveryHst = sh.roundFloat(lcboDeliveryHst + craftDeliveryHst)

  shopIds.forEach( shopId => {
    shopTotals[shopId] = 0
    shopHst[shopId] = 0

    craft[shopId].forEach( item => {
      const price = sh.roundFloat(item.priceCents / 100)
      const amount = sh.roundFloat(price * item.qty)
      const hst = sh.roundFloat(amount * InvoiceDetails.hstRate)
      shopTotals[shopId] += amount
      craftTotal += amount
      craftHst += hst
      shopHst[shopId] += hst
      shopTotals[shopId] = sh.roundFloat(shopTotals[shopId])
      shopHst[shopId] = sh.roundFloat(shopHst[shopId])
    })
  })
  let lcboTotal = 0

  Object.keys(cart.lcboqty).forEach(lcboId => {
    if (!lProds) return
    const prodsForId = lProds[lcboId]
    if (!prodsForId) return
    const { price } = prodsForId
    const p = sh.roundFloat(price / 100)
    const q = cart.lcboqty[lcboId]
    lcboTotal += p * q
  })

  runnerScam = lcboTotal * SCAM_RUNNER_PERCENT / 100
  boozerScam = lcboTotal * SCAM_BOOZER_PERCENT / 100

  const lcboPart = lcboStop ? (lcboTotal + lcboDelivery + lcboDeliveryHst) : 0
  const craftPart = stops ? (craftTotal + craftHst + delivery + deliveryHst) : 0


  const stripeSub = (lcboPart + craftPart) *
                     InvoiceDetails.stripeRate +
                     InvoiceDetails.stripeFixed
  const stripeRound = sh.roundFloat(stripeSub)
  const saveStripe = stripeRound === InvoiceDetails.stripeFixed ? 0 : stripeRound
  let stripe = !cart.isCc ? 0 : saveStripe
  const total = stripe + lcboPart + craftPart

  // ensure the shopIds array is in alpha sorted order
  const sortedIds = sortedShopIds.filter(id => shopIds.includes(id))

  const craftDelPart = stops ? (delivery + deliveryHst ) : 0

  const lcboDeliveryPart = lcboStop ? (lcboDelivery + lcboDeliveryHst) : 0
  const deliverySubtotal = craftDelPart + lcboDeliveryPart 

  return {
    craftGroups: craft,
    craftStops: sortedIds,
    lcboStop: lcboStop,
    shopHst: sh.roundFloat(shopHst),
    shopTotals,
    stops,
    delivery,
    lcboDelivery,
    lcboDeliveryHst,
    deliveryDiscount,
    deliverySubtotal: sh.roundFloat(deliverySubtotal),
    deliveryHst,
    craftTotal: sh.roundFloat(craftTotal),
    craftHst: sh.roundFloat(craftHst),
    lcboTotal: sh.roundFloat(lcboTotal),
    saveStripe: sh.roundFloat(saveStripe),
    stripe: !cart.isCc ? 0 : sh.roundFloat(stripe),
    emailOrder: !cart.isCc,
    isCc: cart.isCc,
    total: sh.roundFloat(total),
    runnerScam: sh.roundFloat(runnerScam),
    boozerScam: sh.roundFloat(boozerScam),
  }
}
