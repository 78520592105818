Object.defineProperty(exports, "__esModule", {
  value: true
});

var _dateHolidays = require('date-holidays');

var _dateHolidays2 = _interopRequireDefault(_dateHolidays);

var _time = require('./time');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
  month starts at 0
  days start at 1
  dayOfWeek starts at 0
*/

// closed Jan 1, Dec 25
var closedOn = ['0,1', '11,25'];

var publicHolidays = function publicHolidays() {
  var holidays = void 0,
      year = void 0,
      daysOff = {};

  year = (0, _time.estObj)().years;

  holidays = new _dateHolidays2.default('CA', 'ON').getHolidays(year);

  holidays.forEach(function (holiday) {
    if (holiday.type === 'public') {
      var d = void 0,
          day = void 0,
          dayOfWeek = void 0,
          md = void 0,
          month = void 0;

      d = new Date(holiday.date);
      dayOfWeek = d.getDay();
      day = d.getDate();
      month = d.getMonth();
      md = month + ',' + day;

      if (!daysOff[month]) {
        daysOff[month] = {};
      }

      daysOff[month][day] = {
        name: holiday.name,
        day: dayOfWeek,
        open: !closedOn.includes(md)
      };
    }
  });
  return daysOff;
};

exports.default = publicHolidays;