import actions from './actions/lcbobeta'
import init from './init/lcbobeta'

const pageInit = 150
// const ALE = 1
// const BUBBLY = 3
const KOLSCH = 75
const BEER_INIT = KOLSCH
const APPLE = 4
const FRUIT = 96
// const VODKA = 20
const MALT = 19
const SELTZER_INIT = MALT
const WHISKEY = 32
// const WHITE = 33
const RED = 23
const WINE_INIT = RED
const c2Map = {
  '1': BEER_INIT,
  '2': APPLE,
  '3': FRUIT,
  '4': SELTZER_INIT,
  '5': WHISKEY,
  '6': WINE_INIT,
}

// we need cat2Id for each cat!!!
export default (state=init, action) => {
  switch (action.type) {
    case actions.setCategory: {
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          category: action.value,
        }
      }
    }
    case actions.cat2IdReset: {
      // why reset everything, why not pass in catid?

      const { catId, cat2Id } = action
      const newCat2 = c2Map[catId]
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          cat2Id,// is this useful?
          cat2ByCat: {
            ...state.lcbobeta.cat2ByCat,
            [catId]: newCat2,
          },
          // reset sale & page
          sale: false,
          page: pageInit,
        }
      }
    }

    case actions.cat2Id: {
      const { catId, cat2Id } = action
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          cat2Id,// is this useful?
          cat2ByCat: {
            ...state.lcbobeta.cat2ByCat,
            [catId]: cat2Id
          },
          // reset sale & page
          sale: false,
          page: pageInit,
        }
      }
    }
    // check for preferred leader, fallback to first if not present
    case actions.cat2ByCat: {
      const { cat2Ids } = action
      //TEMP WORKAROUND AS THIS WAS BUGGY BUT WE WANT BETTER INITIAL VALUES


      const beerCat2 = cat2Ids[1].includes(BEER_INIT) ? BEER_INIT : cat2Ids[1][0]
      const ciderCat2 = cat2Ids[2].includes(APPLE) ? APPLE : cat2Ids[2][0]
      const coolerCat2 = cat2Ids[3].includes(FRUIT) ? FRUIT : cat2Ids[3][0]
      const seltzerCat2 = cat2Ids[4].includes(SELTZER_INIT) ? SELTZER_INIT : cat2Ids[4][0]
      const spiritsCat2 = cat2Ids[5].includes(WHISKEY) ? WHISKEY : cat2Ids[5][0]
      const wineCat2 = cat2Ids[6].includes(WINE_INIT) ? WINE_INIT : cat2Ids[6][0]
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          cat2ByCat: {
            '1': beerCat2,
            '2': ciderCat2,
            '3': coolerCat2,
            '4': seltzerCat2,
            '5': spiritsCat2,
            '6': wineCat2,
          }
        }
      }
    }
    case actions.catId: {
      const { catId, category } = action
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          catId,
          category,
        }
      }
    }
    case actions.page: {
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          page: state.lcbobeta.page + pageInit,
        }
      }
    }
    case actions.prodInProgress: {
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          prodInProgress: false,
        }
      }
    }
    case actions.preload: {
      const { preload, rid } = action
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          preload: {
            ...state.lcbobeta.preload,
            [rid]: preload,
          },
        }
      }
    }
    // case actions.products: {
    //   const { catid, products } = action
    //   return {
    //     ...state,
    //     lcbobeta: {
    //       ...state.lcbobeta,
    //       prodInProgress: false,
    //       prodLastFetch: {
    //         ...state.lcbobeta.prodLastFetch,
    //         [catid]: Date.now(),
    //       },
    //       prodCount: {
    //         ...state.lcbobeta.prodCount,
    //         [catid]: products.length,
    //       },
    //       products: {
    //         ...state.lcbobeta.products,
    //         [catid]: products,
    //       },
    //     }
    //   }
    // }
    case actions.lProds: {
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          lProds: action.value,
        }
      }
    }
    case actions.lCatAgg: {
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          lCatAgg: action.value,
        }
      }
    }
    case actions.sale: {
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          sale: action.sale,
        }
      }
    }
    case actions.scamOpen: {
      const {
        price,
        product,
        scamIsTotal,
        scamIsMobile,
      } = action
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          scamOpen: true,
          scamPrice: price,
          scamIsTotal,
          scamIsMobile,
          scamProduct: product,
        }
      }
    }
    case actions.scamClose: {
      return {
        ...state,
        lcbobeta: {
          ...state.lcbobeta,
          scamOpen: false,
          scamPrice: null,
          scamIsTotal: false,
          scamIsMobile: false,
        }
      }
    }
    default: {
      return state
    }
  }
}
