Object.defineProperty(exports, "__esModule", {
  value: true
});

var _jsonwebtoken = require('jsonwebtoken');

var _jsonwebtoken2 = _interopRequireDefault(_jsonwebtoken);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SESSION_LENGTH = 1440;

var Token = function Token() {
  _classCallCheck(this, Token);
};

Token.default = function (data) {
  return Token.sign(SESSION_LENGTH, data);
};

Token.sign = function (expiaryInMinutes, data) {

  var exp = Math.floor(Date.now() / 1000) + 60 * expiaryInMinutes;

  var sign = {
    exp: exp,
    data: data
  };

  return _jsonwebtoken2.default.sign(sign, data);
};

Token.isValid = function (token) {
  if (!token) {
    return false;
  }

  try {
    var now = Math.floor(Date.now() / 1000);
    var tk = _jsonwebtoken2.default.decode(token);

    if (tk.exp < now) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

Token.decode = function (token) {
  if (!token) {
    return undefined;
  }

  var _jwt$decode = _jsonwebtoken2.default.decode(token),
      iat = _jwt$decode.iat,
      eat = _jwt$decode.eat;

  return { iat: iat, eat: eat };
};

exports.default = Token;