
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.longestOrderTypeLength = exports.OrderTypeFormats = undefined;

var _order_types = require('./order_types');

var _order_types2 = _interopRequireDefault(_order_types);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var longestOrderTypeLength = 0;
for (var prop in _order_types2.default) {
  var len = _order_types2.default[prop].length;
  if (longestOrderTypeLength < len) {
    exports.longestOrderTypeLength = longestOrderTypeLength = len;
  }
}

var OrderTypeFormats = {};
OrderTypeFormats['' + _order_types2.default.auth] = {
  color: 'success',
  outline: true
};
OrderTypeFormats['' + _order_types2.default.capture] = {
  color: 'success'
};
OrderTypeFormats['' + _order_types2.default.confirmed] = {
  color: 'primary',
  outline: true
};
OrderTypeFormats['' + _order_types2.default.declined] = {
  color: 'danger'
};
OrderTypeFormats['' + _order_types2.default.deleted] = {
  color: 'secondary',
  outline: true
};
OrderTypeFormats['' + _order_types2.default.dispute] = {
  color: 'warning'
};
OrderTypeFormats['' + _order_types2.default.refund] = {
  color: 'secondary'
};
OrderTypeFormats['' + _order_types2.default.saved] = {
  color: 'info',
  outline: true
};

exports.OrderTypeFormats = OrderTypeFormats;
exports.longestOrderTypeLength = longestOrderTypeLength;