import { ActionType, createActionTypes } from 'action-types'

const actions = createActionTypes({
  main: {
    drawerToggle: ActionType,
    locOpen: ActionType,
    locClose: ActionType,
    currentTab: ActionType,
    hash: ActionType,//rm
    previousRoute: ActionType,//rm
    routeToAfterLogin: ActionType,
    previousTab: ActionType,
    previousChoice: ActionType,
    device: ActionType,
    infoFontSize: ActionType,
    lcboStatus: ActionType,
    craftStatus: ActionType,
    hideHoursDialog: ActionType,
    showHoursDialog: ActionType,
    toggleHoursLegend: ActionType,
  }
})

const _actions = actions.main
const mainValues = Object.values(_actions)

export default _actions
export { mainValues }
