// pause in ms
const DEFAULT_PAUSE = 60
const SOON_PAUSE = 300
const MED_PAUSE = 666
const LONG_PAUSE = 1750

export default class LinkTo {
  static home = () => LinkTo.route('/home')
  static routeLong = path => LinkTo.local(LONG_PAUSE, path)
  static routePause = path => LinkTo.local(MED_PAUSE, path)
  static routeSoon = path => LinkTo.local(SOON_PAUSE, path)
  static route = path => LinkTo.local(DEFAULT_PAUSE, path)

  static local = (timeout, path) => {
    setTimeout( () => {
      // add leading slash if missing
      if (!path) {
        path = '/'
      } else if (!path.startsWith('/')) {
        path = `/${path}`
      }
      const newUrl = `${window.location.origin}/#${path}`
      window.location.replace(newUrl)
    }, timeout)
  }
}
