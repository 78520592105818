Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = pkgStr;
// because we're denormalizing the database
// this can be shared with lcbo and craft
// The server will need it for processing the new carts
function pkgStr(lPackage) {
  if (!lPackage) return '';
  var type = lPackage.type;
  var volml = lPackage.volml;
  var quantity = lPackage.quantity;

  if (quantity === 1) {
    return volml + 'mL ' + type;
  }
  return quantity + 'x' + volml + 'mL ' + type + 's';
}