import simpleCartInitialState from './init/simplecart'
import actions from './actions/simplecart'
import pickBy from 'lodash.pickby'

export default (state=simpleCartInitialState, action) => {
  const { value } = action

  switch (action.type) {
    case actions.refresh: {
      const { cart } = action
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          ...cart
        },
      }
    }
    case actions.properIdSeen: {
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          properIdSeen: true,
        }
      }
    }
    case actions.clear: {
      return simpleCartInitialState;
    }
    case actions.setToken: {
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          token: action.value
        }
      }
    }
    case actions.setTimeslot: {
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          timeslot: action.value
        }
      }
    }
    case actions.clearCraft: {
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          craft: {},
        }
      }
    }
    case actions.clearLcbo: {
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          lcbo: {},
          lcboCount: 0,
          lcboqty: {},
        }
      }
    }
    case actions.addCraft: {
      const id = value.unitId
      let newQty = value.qty
      if (id in state.simplecart.craft) {
        let item = {...state.simplecart.craft[id]}
        newQty = item.qty + 1
      } else {
        newQty = 1
      }
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          craft: {
            ...state.simplecart.craft,
            [id]: {
              ...value,
              name: value.name,
              qty: newQty
            }
          },
        }
      }
    }
    case actions.removeCraft: {
      // take either the lcboId or the unitId
      const id = value.unitId
      if (id in state.simplecart.craft) {
        let item = {...state.simplecart.craft[id]}

        // dont decrement at zero
        if (item.qty === 0) {
          return state
        }
        let newQty = item.qty - 1
        // remove item if new quantity is zero
        if (newQty <= 0) {
          const filtered = pickBy(
            state.simplecart.craft,
            (val, key) => {
              return state.simplecart.craft[key].unitId !== id
            }
          )
          return {
            ...state,
            simplecart: {
              ...state.simplecart,
              craft: filtered
            }
          }
        }
        return {
          ...state,
          simplecart: {
            ...state.simplecart,
            craft: {
              ...state.simplecart.craft,
              [id]: {
                ...item,
                qty: newQty
              }
            }
          }
        }
      } else {
        return state
      }
    }
    case actions.rmCraftItem: {
      const { unitId } = action
      const { [unitId]: rmItem, ...newCraft } = state.simplecart.craft
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          craft: newCraft,
        }
      }
    }
    case actions.removeCraftStop: {
      const filtered = pickBy(
        state.simplecart.craft,
        (val, key) => {
          return state.simplecart.craft[key].shopId !== action.value
        }
      )
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          craft: filtered
        }
      }
    }

    // use for both inc and dec
    // just pass in qty
    case actions.lcboSetQty: {
      const { lcboId, qty, op } = action
      const change = op === 'inc' ? 1 : -1
      const updatedCount = state.simplecart.lcboCount + change

      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          lcboqty: {
            ...state.simplecart.lcboqty,
            [lcboId]: qty,
          },
          lcboCount: updatedCount < 0 ? 0 : updatedCount,
        }
      }
    }
    case actions.rmLcboItem: {
      const { lcboId } = action
      const { [lcboId]: rmItem, ...newLcbo } = state.simplecart.lcboqty
      const updatedCount = state.simplecart.lcboCount - 1
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          lcboqty: newLcbo,
          lcboCount: updatedCount < 0 ? 0 : updatedCount,
        }

      }
    }
    case actions.isCc: {
      return {
        ...state,
        simplecart: {
          ...state.simplecart,
          isCc: action.value,
        }
      }
    }
    default:
      if (action.type.startsWith('simplecart')) {
        console.warn('simplecart reducer default')
      }
      return state
  }
}
