import { LinkTo, isLoggedIn, Tart } from './'
import * as A from '../reducer/actions'
import cartEmpty from './cartempty'
import checkToken from './checktoken'

// TBD: is there overlap with isLoggedIn?
export default (dispatch, data) => {
  const { session, simplecart } = data
  const { craft, lcboCount } = simplecart
  if(cartEmpty(craft, lcboCount)) {
    Tart.warn('Cart is empty')
    return
  }
  if (!isLoggedIn(session)) {
    Tart.warn('You must be logged in to checkout')
    return
  }
  if (!session.token) {
    Tart.warn('Please login first')
    LinkTo.routeLong('/login')
    return
  }
  if (!checkToken(session.token.exp)) {
    Tart.warn('Your session has expired')
    return
  }
  const { previousRoute } = A.main
  dispatch({ type: previousRoute, value: '' })
  LinkTo.routeSoon('/checkout')
}
