export default device => {
  const MIN = 400
  const MAX = 900
  const mid = device.w >= MIN && device.w < MAX
  if (device.w > MAX)  {
    return {
      maxWidth: '300px',
      height: 'auto',
      float: 'left',
    }
  } else if (mid) {
    return {
      position: 'relative',
      maxWidth: '100px',
      height: 'auto',
      left: '-20px',
    }
  } else {
    return {
      backgroundSize: 'cover',
      position: 'relative',
      maxWidth: '100px',
      height: 'auto',
      left: '-20px',
    }
  }
}