Object.defineProperty(exports, "__esModule", {
    value: true
});
var daySuffix = function daySuffix(dayOfMonth) {
    var j = dayOfMonth % 10;
    var k = dayOfMonth % 100;
    if (j === 1 && k !== 11) {
        return 'st';
    }
    if (j === 2 && k !== 12) {
        return 'nd';
    }
    if (j === 3 && k !== 13) {
        return 'rd';
    }
    return 'th';
};
exports.default = daySuffix;