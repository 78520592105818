import { axUser } from '../config/ax'
import to from 'await-to-js'
import { Tart, storage } from '../../utility'
// import clearLocal from '../../utility/clearlocal'
import { actions as A } from '../index'
import { Express } from '../../config'

// special case: we need session here, its not being used in axios
export default async(dispatch, session, silent=false, cb) => {
  if (!silent) {
    const conf = window.confirm('Signout? Are you sure?')
    if (!conf) return
  }
  // clearLocal()
  localStorage.removeItem(storage.keys.sessionToken)
  dispatch({ type: A.address.clearData })
  dispatch({ type: A.carts.clear })
  dispatch({ type: A.cart.clear })

  if (!session || !session.token || !session.token.t || !session.email) {
    console.warn('SIGNOUT: invalid session')
    if (cb) {
      cb()
    }
    return
  }

  const [err] = await to(
    axUser({
      method: 'UNLINK',
      url: Express.signin,
      data: { isSignout: true }
    })
  )
  if (err) {
    console.log(err)
  }
  dispatch({ type: A.session.logout })
  if (!silent) {
    Tart.info('You have been signed out')
  }
  if (cb) {
    cb()
  }
}
