import Urls from './urls'

// TODO
// comment out unused routes
// also find if any routes are not listed
export default {
  activeShops: Urls.make('activeshops'),
  address: Urls.make('address'),
  bottle: Urls.make('bottle'),
  bottleshops: Urls.make('bottleshops'),
  checkout: Urls.make('checkout'),
  comingSoon: Urls.make('comingsoon'),
  confirmations: Urls.make('confirmations'),
  convert: Urls.make('convert'),
  cPreload: Urls.make('cpreload'),
  cTables: Urls.make('ctables'),
  dsched: Urls.make('dsched'),
  email: Urls.make('email'), // axuser-->PATCH 
  emailLookup: Urls.make('emaillookup'),
  // errorTest: Urls.make('errortest'),
  hours: Urls.make('hours'),
  imgInfo: Urls.make('imginfo'),
  imgUrls: Urls.make('imgurls'),
  inventory: Urls.make('inventory'),

  // beta routes
  lPreload: Urls.make('lpreload'),
  lProd: Urls.make('lprod'),
  
  // goal is to replace lProd with lProds and lCatAgg
  lCatAgg: Urls.make('lcatagg'),
  lProds: Urls.make('lprods'),

  // lcbo: Urls.make('lcbo'),

  lcboProduct: Urls.make('prod'),

  // get rid of this...
  // lcboProducts: Urls.lambda('lcboprod'),
  
  manageLcbo: Urls.make('managelcbo'),
  manageOrders: Urls.make('manageorders'),
  mapping: Urls.make('map'),
  numbers: Urls.make('numbers'), // todo purge or validate usage
  orders: Urls.make('orders'),
  orderinfo: Urls.make('orderinfo'),
  phone: Urls.make('phone'),
  phonever: Urls.make('phonever'),
  ping: Urls.make('ping'),
  pricing: Urls.make('pricing'),
  pwd: Urls.make('pwd'),
  shop: Urls.make('shop'),
  signin: Urls.make('signin'),
  signup: Urls.make('signup'),
  telVerify: Urls.make('telverify'),
  token: Urls.make('token'),
  units: Urls.make('units'), // lambda?
  unsub: Urls.make('unsub'),
}
