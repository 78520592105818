import padLcboId from './padlcboid'
import lcboGeneric from '../img/lcbogeneric'
import { constants } from '../config'

//TODO USE ALT URL for fallback
const base = 'https://d2xz91cvqs514f.cloudfront.net/'

const altImg = id => `https://www.lcbo.com/content/dam/lcbo/products/${id}.jpg/jcr:content/renditions/cq5dam.web.1280.1280.jpeg`

const {
  LCBO_SM,
  LCBO_MD,
  LCBO_LG,
} = constants

const defaultSize = LCBO_SM
// images can be 234, 720 or 1280
const sizes = [ LCBO_SM, LCBO_MD, LCBO_LG] 

export default function lcboImgurl(id, size, alt, fail) {
  if (!sizes.includes(size)) {
    size = defaultSize
  }
  if (!alt && !fail) {
    const imgSize = `${size}x${size}`
    const padId = padLcboId(id)
    const url = `${base}${imgSize}/${padId}.jpg`
    return url
  } else if (alt) {
    const padId = padLcboId(id, alt)
    const url = altImg(padId)
    return url
  } else {
    // todo get a better generic or don't render the image at all
    return lcboGeneric()
  }
}