/* eslint no-unused-vars: ["warn", { "varsIgnorePattern": "tResp|err" }] */
import axios from 'axios'
import to from 'await-to-js'
import { Express, Msgs } from '../../config'
import Stage from '../util/stage'
import {
  getItem,
  storageKeys as keys,
  Tart
} from '../../utility'
import { StatusCodes as http } from 'http-status-codes'
import signinFinal from './signinfinal'

export default async(dispatch, token, tokenType, cb) => {
  const url = Express.token
  const action = 'signinToken'
  Stage.init(action, dispatch)
  const sessionToken = getItem(keys.sessionToken)

  const [tErr, tResp] = await to(
    axios.post(url, {
      sessionToken,
      token,
      tokenType,
    })
  )
  if (tErr) {
    console.log(tErr)
    const { response } = tErr
    if (response) {
      const { status } = response
      if (status === http.CONFLICT) {
        Tart.warn(Msgs.login.sessionConflict)
      }
    }
    Stage.err(action, dispatch, tErr)
    return
  }
  const [err, resp] = await to(
    axios.patch(
      Express.signin, {
        tokid: token,
        tokenType,
        sessionToken,
      }
    )
  )
  Stage.ok(action, dispatch, resp)
  const { data } = resp
  signinFinal(dispatch, data, cb)
}
