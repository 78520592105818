import { Lambda } from '../../config'
import axios from 'axios'
import { actions as A } from '../index'
import to from 'await-to-js'
import { estObj, getEstHours } from '../../@craft-delivery/cfg/dist/util'
import { storage, subdomain } from '../../utility'

// consolidate labmda functions as much as possible
// anything called on initialization of the web app should be here
// we may wish to call independently to refresh so leave endpoint actions as is
export default async(dispatch) => {
  // todo pass subdomain to lambda
  const [subHost] = subdomain()

  let dateObj = estObj()
  const est = getEstHours() + 100
  const [err, resp] = await to(
    axios.post(Lambda.initClient, {
        est,
        month: dateObj.months + 1,
        year: dateObj.years,
        subHost,
      }
    )
  )
  if (err) {
    console.log(err)
    return
  }
  const {
    areas,
    // craftStyles,
    days,
    inventories,
    postalCodes,
    regions,
    schedules,
    slots,
    unitData,
    // lookups,
  } = resp.data
  const { daysOpen, daysClosed } = days
  const { current, next } = schedules

  
  const {
    units,
    unitDetailsById,
  } = unitData
  
  dispatch({
    type: A.regions.subdomain,
    value: subHost,
  })
  
  // dispatch({
  //   type: A.lookups.init,
  //   data: lookups,
  // })

  dispatch({
    type: A.regions.areas,
    areas,
  })

  // we can piggyback of of lcbo varietals
  // and assign a varid to each bottle
  // dispatch({
  //   type: A.bottleshop.craftStyles,
  //   craftStyles: craftStyles.styles,
  // })
  // console.log(areas)
  // console.log(regions)

  dispatch({
    type: A.regions.list,
    value: regions,
    postalCodes,
  })
  // must follow list so we overwrite default region
  const regionStore = storage.getItem(storage.keys.region)
  if (regionStore) {
    const regionObj = JSON.parse(regionStore)
    // in FTW
    if (regionObj.id in areas) {
      console.log('Setting region to saved region: ' + regionObj.region_short)
      dispatch({
        type: A.regions.setCurrent,
        region: regionObj,
      })
    } else {
      localStorage.removeItem(storage.keys.region)
    }
  }

  dispatch({ type: A.delivery.noContent, value: false })
  dispatch({ type: A.delivery.scheduleNextMonth, value: next })
  dispatch({ type: A.delivery.schedule, value: current })

  dispatch({
    type: A.bottleshop.inventories,
    value: inventories,
  })

  // for (const id in inventories) {
  //   dispatch({
  //     type: A.bottleshop.inventories,
  //     value: inventories[id],
  //   })
  //   dispatch({
  //     type: A.regions.invFresh,
  //     regionId: id,
  //   })
  // }

  dispatch({
    type: A.bottleshop.setUnits,
    units,
    unitDetailsById,
  })

  const availSlotIds = Object.keys(slots.available)
  if (availSlotIds.length) {
    const timeslot = slots.available[availSlotIds[0]]
    dispatch({
      type: A.cart.setTimeslot,
      value: timeslot,
    })
  }
  dispatch({
    type: A.delivery.available,
    value: slots.available,
  })
  dispatch({
    type: A.delivery.timeslots,
    value: slots.all,
  })

  dispatch({
    type: A.delivery.daysOpen,
    value: daysOpen,
  })
  dispatch({
    type: A.delivery.daysClosed,
    value: daysClosed,
  })
}