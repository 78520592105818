import get from 'lodash.get'
import reducers from './reducers'

export default (state, keys) => {
  let group = {}
  // allow sending a single reducer without ['reducerName']
  if (!Array.isArray(keys)) {
    group[keys] = get(state, reducers[keys])
    return group
  }
  for (let i=0, len=keys.length; i < len; i++) {
    const key = keys[i]
    group[key] = get(state, reducers[key])
  }
  return group
}
